import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkspaces, createWorkspace, deleteWorkspace } from '../actions/WorkspaceActions';
import { useNavigate } from 'react-router-dom';

const Workspaces = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const workspaces = useSelector(state => state.workspaceStore.workspaces);
    const [showModal, setShowModal] = useState(false);
    const [newWorkspaceName, setNewWorkspaceName] = useState('');

    useEffect(() => {
        dispatch(fetchWorkspaces());
    }, [dispatch]);

    const handleCreateWorkspace = () => {
        if (newWorkspaceName.trim()) {
            dispatch(createWorkspace(newWorkspaceName));
            setNewWorkspaceName('');
            setShowModal(false);
        }
    };

    const handleDeleteWorkspace = (id) => {
        if (window.confirm('Are you sure you want to delete this workspace?')) {
            dispatch(deleteWorkspace(id));
        }
    };

    const handleWorkspaceClick = (id) => {
        navigate(`/workspaces/${id}`);
    };

    if (workspaces === null) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-100">
                <div className="text-2xl font-bold">Loading...</div>
            </div>
        );
    }

    return (
        <div className="flex justify-center min-h-screen bg-gray-100">
            <div className="w-1/2 p-8">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold">Workspaces</h1>
                    <button
                        onClick={() => setShowModal(true)}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                        New Workspace
                    </button>
                </div>

                {workspaces.length === 0 ? (
                    <div className="text-center py-8">
                        <p className="text-xl text-gray-600">No workspaces found.</p>
                        <p className="text-gray-500 mt-2">Create a new workspace to get started!</p>
                    </div>
                ) : (
                    <div className="space-y-4">
                        {workspaces.map(workspace => (
                            <div key={workspace.id} className="flex justify-between items-center bg-white p-4 rounded shadow">
                                <span 
                                    className="cursor-pointer hover:text-blue-500"
                                    onClick={() => handleWorkspaceClick(workspace.id)}
                                >
                                    {workspace.name}
                                </span>
                                <button
                                    onClick={() => handleDeleteWorkspace(workspace.id)}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded"
                                >
                                    Delete
                                </button>
                            </div>
                        ))}
                    </div>
                )}

                {showModal && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                        <div className="bg-white p-5 rounded-lg shadow-xl">
                            <h2 className="text-xl mb-4">Create New Workspace</h2>
                            <input
                                type="text"
                                value={newWorkspaceName}
                                onChange={(e) => setNewWorkspaceName(e.target.value)}
                                placeholder="Workspace name"
                                className="border p-2 mb-4 w-full"
                            />
                            <div className="flex justify-end">
                                <button
                                    onClick={() => setShowModal(false)}
                                    className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleCreateWorkspace}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Workspaces;
