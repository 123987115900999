import React from 'react'
import Label from './Label'
// import BubbleCheckbox from '../components/BubbleCheckbox'

const MultiSelect = ({ items, textKey="text", value, onClick, mode="heading", label, spacerTop, spacerBottom, subLabel }) => {

  const renderItems = () => {
    return items.map((n) => {
      return <div onClick={() => onClick(n.value)} className={`text-center px-10 w-full justify-center flex items-center font-medium py-3 ${value === n.value ? "bg-indigo-500 text-white shadow-subtle" : "opacity-50 bg-gray-100 text-black cursor-pointer hover:opacity-50 filter"} rounded-md`}>{n[textKey]}</div>
    })
  }

  const renderLabel = () => {
    if (label) {
      return <Label subLabel={subLabel} spacerTop={spacerTop} input={true} text={label}/>
    }
  }

  return (
    <>
      {renderLabel()}
      <div className="flex rounded-md filter shadow-subtle-sm gap-x-4">
        {renderItems()}
      </div>
    </>
  )
  
}

export default MultiSelect;
