import React from 'react'
import Navbar from './Navbar'
import Icons from './Icons'
import { useNavigate, useLocation } from 'react-router-dom'

const Sidebar = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  console.log(location)

  const active = (path) => {
    return location.pathname == path
  }

  return (
    <div className="md:h-full bg-slate-50 p-4 md:p-10">
      <div className="flex md:flex-col md:mt-20 gap-10">
        <div onClick={() => navigate('/')} className="flex items-center gap-x-2 cursor-pointer hover:opacity-50">
          <div className={`p-2 ${active("/") ? "bg-indigo-500" : "bg-slate-300"} rounded-md`}>
            <Icons className="w-4 h-4" color={`${active("/") ? "white" : "slate-400"}`} icon="personalise"/>
          </div>
          <div className={`${active('/') ? 'text-indigo-500' : 'text-slate-400'} font-medium whitespace-pre`}>Client Scan</div>
        </div>
        <div onClick={() => navigate('/offer')} className="flex items-center gap-x-2 cursor-pointer hover:opacity-50">
          <div className={`p-2 ${active("/offer") ? "bg-indigo-500" : "bg-slate-300"} rounded-md`}>
            <Icons className="w-4 h-4" color={`${active("/offer") ? "white" : "slate-400"}`} icon="offer"/>
          </div>
          <div className={`${active('/offer') ? 'text-indigo-500' : 'text-slate-400'} font-medium whitespace-pre`}>Offer</div>
        </div>
        {/* <div onClick={() => navigate('/tone-of-voice')} className="flex items-center gap-x-2 cursor-pointer hover:opacity-50">
          <div className={`p-2 ${active("/tone-of-voice") ? "bg-indigo-500" : "bg-slate-300"} rounded-md`}>
            <Icons className="w-4 h-4" color={`${active("/tone-of-voice") ? "white" : "slate-400"}`} icon="toneOfVoice"/>
          </div>
          <div className={`${active('/tone-of-voice') ? 'text-indigo-500' : 'text-slate-400'} font-medium whitespace-pre`}>
            <div>Tone of Voice</div>
            <div className="text-xs">Coming Soon</div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Sidebar;
