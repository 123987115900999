import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { showAccount } from '../actions'
import { useParams, useNavigate } from 'react-router-dom'
import BackButton from '../components/BackButton'

const AccountShow = () => {
    const { searchId, accountId } = useParams()
    const account = useSelector(state => state.accountStore.account)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()

    useEffect(() => {
        if (account === null) {
            dispatch(showAccount(searchId, accountId))
        } else {
            setLoading(false)
        }
    }, [account])

    console.log(account)

    return (
        <>
            {/* <div className='h-full absolute top-0 bottom-0 w-full'>
                <img className='w-full' src="https://storage.screenshotapi.net/0161creative_co_uk__c71a9811926d.png"/>
                <div className='absolute bg-gray-900/90 h-full w-full z-10 absolute top-0'></div>
            </div> */}
            
            <div className='p-4 border-4'>
            <BackButton onClick={() => navigate(-1)}/>
                <div className='text-2xl font-bold'>LEAD DETAILS WILL SHOW HERE IN FULL</div>
            </div>
        </>
    )
}

export default AccountShow