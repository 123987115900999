import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Checkbox from '../../components/Checkbox'
import BackButton from '../../components/BackButton'
import { useNavigate } from "react-router-dom";
import { createSearch } from '../../actions'
import { validateEmail } from '../../helpers/validatorHelper'

const Settings = ({ file, domain, domainColumn, emailColumn, onStageChange, inputType, selectedServices, offer }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('')
  const fileResponse = useSelector(state => state.fileStore.file)
  const [submitting, setSubmitting] = useState(false)
  const [findBestContact, setFindBestContact] = useState(false)

  const personaliseLeads = () => {

    setSubmitting(true)

    const formData = new FormData();
    formData.append('search[file]', file);
    formData.append('search[domain]', domain)
    formData.append('search[domain_column]', domainColumn?.name);
    formData.append('search[email_column]', emailColumn?.name);
    formData.append('search[email_results_to]', email);
    formData.append('search[find_best_contact]', true);
    formData.append('search[services]', selectedServices);
    formData.append('search[offer]', offer);
    formData.append('search[input_type]', inputType);

    dispatch(createSearch(formData))
    setSubmitting(true)
  }

  console.log(fileResponse)

  const isValid = () => {
    return email.length > 0 && validateEmail(email) || true
  }

  const renderEmailResultsTo = () => {
    if (inputType === 'csv') {
      return <Input onChange={(e) => setEmail(e)} placeholder="email@email.co" spacerTop={true} value={email} label="Email result to"/>
    }
  }

  return (
    <div className="p-10 rounded-lg border">
      <BackButton onClick={() => inputType === 'domain' ? onStageChange('input') : onStageChange('selectColumn')}/>
      <div className='text-xl text-indigo-500 mb-4'>Settings</div>
      <div>
        <div className="bg-slate-50 rounded-lg p-4">
          <Checkbox value={findBestContact} onClick={() => setFindBestContact(!findBestContact)} label="Find the contact details of the best person to contact"/>
        </div>
      </div>
      <Button displaySpinner={submitting} onClick={() => personaliseLeads()} disabled={!isValid()} spacerTop={true} text="Start Search"/>
    </div>
  )
}

export default Settings;
