import { BACKEND_URL } from './helpers/backendHelper';

const formatHeaders = (auth, method, body=null, file ) => {
  if (auth) {
    return (
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`
        },
        method: method,
        body: body
      }
    )
  } else {
    if (file) {
      return (
        {
          method: method,
          body: body
        }
      )
    } else {
      return (
        {
          headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          },
          method: method,
          body: body
        }
      )
    }
  }
}

async function get(params, auth=false) {
  const url = BACKEND_URL + params
  return fetch(url, formatHeaders(auth, "GET")).then(function(response) {
    return response;
  });
}

async function post(params, body=null, auth=false, file=false) {
  const url = BACKEND_URL + params
  return fetch(url, formatHeaders(auth, "POST", body, file)).then(function(response) {
    return response;
  });
}

async function patch(params, body=null, auth=false) {
  const url = BACKEND_URL + params
  return fetch(url, formatHeaders(auth, "PATCH", body)).then(function(response) {
    return response;
  });
}

async function deleteRequest(params, auth=false) {
  const url = BACKEND_URL + params
  return fetch(url, formatHeaders(auth, "DELETE")).then(function(response) {
    return response;
  });
}

// SEARCHES

function searchCreate(payload) {
  return post(`/api/v1/searches`, payload, null, true);
}

function fileDetectColumns(payload) {
  return post(`/api/v1/file/detect_columns`, payload, null, true);
}

function fileImportDomains(payload) {
  return post(`/api/v1/file/import_domains`, payload, null, true);
}

function searchShow(searchId) {
  return get(`/api/v1/searches/${searchId}`);
}

function auditCreate(domain, service) {
  return post(`/api/v1/audits`, JSON.stringify({
    domain: domain,
    services: service
  }));
}

function scoresCheck(data){
  return post(`/searches/scores`, JSON.stringify({
    search: data
  }));
}

function offerRewrite(data){
  console.log("DATA", data)
  return post(`/offer/rewrite`, JSON.stringify({
    offer: data
  }));
}

// ACCOUNTS

function untrainedAccountShow(payload) {
  return get(`/model_trainings/new`, payload, null, true);
}

// MODEL TRAINING

function modelTrainingCreate(accountId, data){
  return post(`/accounts/${accountId}/model_trainings`, JSON.stringify({
    model_training: data
  }));
}

// WORKSPACES

function workspacesFetch() {
  return get(`/api/v1/workspaces`);
}

function workspaceCreate(data) {
  return post(`/api/v1/workspaces`, JSON.stringify({
    workspace: data
  }));
}

function accountShow(searchId, accountId) {
  return get(`/api/v1/searches/${searchId}/accounts/${accountId}`);
}

function workspaceUpdate(id, data) {
  return patch(`/api/v1/workspaces/${id}`, JSON.stringify({
    workspace: data
  }));
}

function workspaceDelete(id) {
  return deleteRequest(`/api/v1/workspaces/${id}`);
}

function workspaceShow(id) {
  return get(`/api/v1/workspaces/${id}`);
}

export {
  fileDetectColumns, fileImportDomains,
  searchCreate, searchShow, scoresCheck, auditCreate,
  accountShow,
  untrainedAccountShow,
  modelTrainingCreate,
  offerRewrite,
  workspacesFetch, workspaceCreate, workspaceUpdate, workspaceDelete, workspaceShow
}
