import React, { useEffect, useState } from 'react'
import CableApp from '../../cable'

const Loading = () => {
    const [searchResult, setSearchResult] = useState(null);

    useEffect(() => {
        const channel = CableApp.cable.subscriptions.create(
            { channel: "SearchChannel" },
            {
                connected: () => {
                    console.log("Connected to SearchChannel");
                },
                received: (data) => {
                    console.log("WEBSOCKET DATA", data);
                    setSearchResult(data.result);
                },
                disconnected: () => {
                    console.log("Disconnected from SearchChannel");
                },
            }
        );

        return () => {
            channel.unsubscribe();
        };
    }, []);


    return (
        <div>
            <h1>Loading...</h1>
        </div>
    )
}

export default Loading