import React, { useState, useCallback, useRef, useEffect } from 'react';
import CableApp from '../cable';
import { useSelector, useDispatch } from 'react-redux';
import { createSearch, detectColumnsFile, importDomains, showWorkspace, updateWorkspace } from '../actions';
import CustomHeaderComponent from '../components/CustomHeaderComponent';
import WorkspaceHeader from '../components/WorkspaceHeader';
import WorkspaceGrid from '../components/WorkspaceGrid';
import ColumnManager from '../components/ColumnManager';
import DomainImporter from '../components/DomainImporter';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useParams } from 'react-router-dom';
// Add this import for the drag handle icon
import { FaBars } from 'react-icons/fa';

const WorkspaceShow = () => {
    const { workspace_id } = useParams();
    const dispatch = useDispatch();
    const workspace = useSelector(state => state.workspaceStore.workspace);
    const search = useSelector(state => state.searchStore.search);
    const uploadedFile = useSelector(state => state.fileStore.file)
    const domains = useSelector(state => state.fileStore.domains)
    const [question, setQuestion] = useState('');
    const [exampleJson, setExampleJson] = useState('');
    const [selectedOptions, setSelectedOptions] = useState('');
    const [selectedPreset, setSelectedPreset] = useState('');
    
    const [rowData, setRowData] = useState([]);
    const [rowStart, setRowStart] = useState(1);
    const [rowEnd, setRowEnd] = useState(1);
    const [file, setFile] = useState(null);
    const [columns, setColumns] = useState([]);
    const [selectedDomainColumn, setSelectedDomainColumn] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showManageColumns, setShowManageColumns] = useState(false);
    const [showImportDomains, setShowImportDomains] = useState(false);
    const [importOption, setImportOption] = useState('append');
    const [showFileUpload, setShowFileUpload] = useState(true);
    const [selectedRowCount, setSelectedRowCount] = useState(0);
    const [isManageColumnsOpen, setIsManageColumnsOpen] = useState(true);
    const [editingColumn, setEditingColumn] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [initialLoad, setInitialLoad] = useState(false)
    const [processingCells, setProcessingCells] = useState({});

     // Add this new component for the drag handle
     const DragHandle = () => {
        return <FaBars style={{ color: '#888', fontSize: '16px' }} />;
    };

    console.log("WS", workspace)

    useEffect(() => {
        if (workspace_id) {
            dispatch(showWorkspace(workspace_id));
        }
    }, [dispatch, workspace_id]);

    const handleEditColumn = (columnField) => {
        console.log(`Edit column: ${columnField}`);
        const column = workspace.data.columns.find(col => col.field === columnField);
        if (column) {
            setEditingColumn({
                field: column.field,
                headerName: column.headerName,
                question: column.script?.question || '',
                exampleJson: column.script?.example_json || '',
                selectedOptions: column.script?.selectedOptions || 'run_for_all_rows',
                rowStart: column.script?.rowStart || 1,
                rowEnd: column.script?.rowEnd || 1
            });
            setIsManageColumnsOpen(true);
        }
    };

    const parseExampleJson = (exampleJson) => {
        try {
            const parsedJson = JSON.parse(exampleJson);
            return Object.keys(parsedJson).map(key => ({
                field: `${key.toLowerCase()}`,
                headerName: key,
                editable: true,
                headerEditable: true,
                wrapText: true
            }));
        } catch (error) {
            console.error("Invalid JSON:", error);
            return [];
        }
    };

    const runCell = (params) => {
        console.log("runCell", params);
        const { column, node } = params;
        const columnField = column.getColId();
        const rowIndex = node.rowIndex;
        
        // Set the cell to 'processing' state
        setProcessingCells(prev => ({
            ...prev,
            [`${rowIndex}-${columnField}`]: true
        }));
    
        console.log("processingCells", processingCells);
        
        // Find the column in the workspace data
        const workspaceColumn = workspace.data.columns.find(col => col.field === columnField);
    
        if (!workspaceColumn || !workspaceColumn.script) {
            console.error("Column definition or script not found");
            return;
        }
    
        const { question, example_json } = workspaceColumn.script;
    
        // Get the domain for this row
        const domain = rowData[rowIndex].domain;
    
        let data = {
            "domains": [domain],
            "question": question,
            "column_name": columnField,
            "example_json": example_json
        };
    
        console.log("Sending data for single cell:", data);
        dispatch(createSearch(workspace_id, data));
    
        if (gridApi) {
            gridApi.refreshCells({ force: true });
        }
    };

    

    const CellRenderer = React.memo((props) => {
        const cellKey = `${props.node.rowIndex}-${props.column.getColId()}`;
        const isProcessing = props.processingCells[cellKey];
    
        if (props.colDef.headerName === "Domain" || props.colDef.headerName === "#" || props.colDef.headerName === "") {
            return props.value;
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    {isProcessing ? 'Processing...' : props.value}
                </div>
                <div 
                    style={{ 
                        cursor: 'pointer', 
                        opacity: 0.5, 
                        transition: 'opacity 0.3s',
                        marginLeft: '5px'
                    }}
                    onMouseEnter={(e) => e.target.style.opacity = 1}
                    onMouseLeave={(e) => e.target.style.opacity = 0.5}
                    onClick={() => props.runCell(props)}
                >
                    ▶
                </div>
            </div>
        );
    });

    useEffect(() => {
        if (workspace && workspace.data && initialLoad === false) {
            setInitialLoad(true)
            setShowManageColumns(true)
            
            // Ensure there's always a domain column
            let workspaceColumns = workspace.data.columns || [];
            if (workspaceColumns.length === 0 || !workspaceColumns.some(col => col.field === 'domain')) {
                workspaceColumns = [{
                    field: 'domain',
                    headerName: 'Domain',
                    width: 300
                }, ...workspaceColumns];
            }

            // Set up the workspace data
            setRowData(workspace.data.rows || []);
            
            // Set up the columns
            const newColDefs = [
                {
                    headerName: '',
                    width: 40,
                    lockPosition: true,
                    cellRenderer: DragHandle,
                    cellStyle: {cursor: 'move'},
                    rowDrag: true,
                    suppressMenu: true,
                    suppressMovable: true,
                    pinned: 'left',
                    filter: false,
                },
                {
                    headerName: '#',
                    valueGetter: 'node.rowIndex + 1',
                    width: 50,
                    pinned: 'left',
                    filter: false,
                },
                {
                    field: 'select',
                    headerName: '',
                    checkboxSelection: true,
                    headerCheckboxSelection: true,
                    width: 50,
                    pinned: 'left',
                    filter: false,
                },
                ...workspaceColumns.map(col => ({
                    field: col.field,
                    headerName: col.headerName,
                    colId: col.headerName,
                    width: col.width,
                    editable: true,
                    headerEditable: true,
                    wrapText: true,
                    cellRenderer: CellRenderer,
                    headerComponent: CustomHeaderComponent,
                    headerComponentParams: {
                        menuItems: ['Edit'],
                        onHeaderClick: () => {
                            handleEditColumn(col.field);
                        },
                        onMenuItemClick: (item) => {
                            if (item === 'Edit') {
                                handleEditColumn(col.field);
                            }
                        },
                    },
                    filter: true,
                }))
            ];
            setColDefs(newColDefs);
            
            // Set other necessary state based on the workspace data
            setColumns(workspaceColumns);
            
            // Close the manage columns menu if there's data
            setIsManageColumnsOpen(workspace.data.rows.length === 0);
        }
    }, [workspace, initialLoad]);

    console.log(domains)
    
    useEffect(() => {
        if (uploadedFile) {
            setColumns(uploadedFile.columns)
        }
    }, [uploadedFile])

    useEffect(() => {
        if (domains && domains.domains) {
            const newRowData = domains.domains.map(domain => ({ domain }));
            if (importOption === 'append') {
                setRowData(prevRowData => [...prevRowData, ...newRowData]);
            } else {
                setRowData(newRowData);
            }
            setShowManageColumns(true);
            setShowImportDomains(false);
        }
    }, [domains, importOption]);

    useEffect(() => {
        if (workspace && isSaving) {
            setIsSaving(false)
        }
    }, [workspace, isSaving])

    console.log(uploadedFile)

    console.log("rowData", rowData)

    useEffect(() => {
        const channel = CableApp.cable.subscriptions.create(
        { channel: "WorkspaceChannel", workspace_id: workspace_id },
          {
            connected: () => {
              console.log("Connected to WorkspaceChannel", workspace_id);
            },
            received: (data) => {
              console.log("WEBSOCKET DATA", data);
              updateRowData(data.account);
            },
            disconnected: () => {
              console.log("Disconnected from WorkspaceChannel", workspace_id);
            },
          }
        );
    
        // Cleanup subscription on component unmount
        return () => {
          channel.unsubscribe();
        };
      }, []);
    
    const [colDefs, setColDefs] = useState([
        {
            headerName: '',
            width: 40,
            lockPosition: true,
            cellRenderer: DragHandle,
            cellStyle: {cursor: 'move'},
            rowDrag: true,
            suppressMenu: true,
            suppressMovable: true,
            pinned: 'left',
            filter: false, // Disable filter for this column
        },
        {
            headerName: '#',
            valueGetter: 'node.rowIndex + 1',
            width: 50,
            pinned: 'left',
            filter: false, // Disable filter for this column
        },
        {
            field: 'select',
            headerName: '',
            checkboxSelection: true,
            headerCheckboxSelection: true,
            width: 50,
            pinned: 'left',
            filter: false, // Disable filter for this column
        },
        { 
            field: "domain", 
            headerName: "domain", 
            editable: true, 
            headerEditable: true, 
            width: 300,
            wrapText: false,
            cellRenderer: CellRenderer,
            filter: true, // Enable filter for domain column
        },
    ]);

    const [newColumnName, setNewColumnName] = useState('');
    const [gridApi, setGridApi] = useState(null);

    const updateRowData = (data) => {
        setRowData(prevRowData => {
            const updatedRowData = prevRowData.map(row => {
                if (row.domain === data.domain) {
                    const updatedRow = { ...row };
                    if (data.checks) {
                        Object.entries(data.checks).forEach(([key, value]) => {
                            if (typeof value === 'object' && value !== null) {
                                // For nested objects, use the outer key (with question mark)
                                updatedRow[key] = Object.values(value)[0];
                            } else {
                                updatedRow[key] = value;
                            }
                        });
                    }
                    return updatedRow;
                }
                return row;
            });
    
            // Clear the processing state for the updated cells
            setProcessingCells(prev => {
                const newProcessingCells = { ...prev };
                const rowIndex = updatedRowData.findIndex(row => row.domain === data.domain);
                if (rowIndex !== -1 && data.checks) {
                    Object.keys(data.checks).forEach(key => {
                        delete newProcessingCells[`${rowIndex}-${key}`];
                    });
                }
                return newProcessingCells;
            });
    
            console.log("Updated rowData:", updatedRowData);
            return updatedRowData;
        });
    };


    const captureTableData = () => {
        if (gridApi) {
            const columnState = gridApi.getColumnState();
            const columnData = columnState.map(col => {
                const columnDef = gridApi.getColumnDef(col.colId);
                return {
                    field: columnDef.field,
                    headerName: columnDef.headerName,
                    width: col.width,
                };
            });

            const rowData = [];
            gridApi.forEachNode(node => {
                rowData.push(node.data);
            });

            const tableData = {
                columns: columnData.filter(col => col.field !== 'select' && col.headerName !== '#' && col.field !== undefined),
                rows: rowData
            };

            let data = {
                data: tableData
            }

            console.log("Captured table data:", data);

            // Make API call to the server
            setIsSaving(true);
            dispatch(updateWorkspace(workspace_id, data))
                .then(() => {
                    setIsSaving(false);
                    gridApi.refreshCells({ force: true });
                })
                .catch(() => setIsSaving(false));

            return tableData;
        }
    };

    const handleAddColumn = () => {
        if (newColumnName.trim()) {
            let parsedJson;
            try {
                parsedJson = JSON.parse(exampleJson);
            } catch (error) {
                console.error("Invalid JSON:", error);
                return;
            }
    
            let newColumns;
            if (selectedPreset === 'instagram_management_score') {
                newColumns = [
                    {
                        field: `${newColumnName.trim()}`,
                        headerName: `${newColumnName.trim()}`,
                        colId: `${newColumnName.trim()}`,
                        editable: true,
                        headerEditable: true,
                        wrapText: true,
                        cellRenderer: CellRenderer,
                        headerComponent: CustomHeaderComponent,
                        headerComponentParams: {
                            menuItems: ['Edit'],
                            onHeaderClick: () => handleEditColumn(`${newColumnName.trim()}`),
                            onMenuItemClick: (item) => {
                                if (item === 'Edit') {
                                    handleEditColumn(`${newColumnName.trim()}`);
                                }
                            },
                        },
                        sortable: false,
                        script: {
                            question: question,
                            example_json: exampleJson
                        }
                    },
                    {
                        field: `${newColumnName.trim()} Reasoning`,
                        headerName: `${newColumnName.trim()} Reasoning`,
                        colId: `${newColumnName.trim()} Reasoning`,
                        editable: true,
                        headerEditable: true,
                        wrapText: true,
                        cellRenderer: CellRenderer,
                        headerComponent: CustomHeaderComponent,
                        headerComponentParams: {
                            menuItems: ['Edit'],
                            onHeaderClick: () => handleEditColumn(`${newColumnName.trim()} Reasoning`),
                            onMenuItemClick: (item) => {
                                if (item === 'Edit') {
                                    handleEditColumn(`${newColumnName.trim()} Reasoning`);
                                }
                            },
                        },
                        sortable: false,
                        script: {
                            question: question,
                            example_json: exampleJson
                        }
                    }
                ];
            } else {
                newColumns = [{
                    field: `${newColumnName.trim()}`,
                    headerName: `${newColumnName.trim()}`,
                    colId: `${newColumnName.trim()}`,
                    editable: true,
                    headerEditable: true,
                    wrapText: true,
                    cellRenderer: CellRenderer,
                    headerComponent: CustomHeaderComponent,
                    headerComponentParams: {
                        menuItems: ['Edit'],
                        onHeaderClick: () => handleEditColumn(`${newColumnName.trim()}`),
                        onMenuItemClick: (item) => {
                            if (item === 'Edit') {
                                handleEditColumn(`${newColumnName.trim()}`);
                            }
                        },
                    },
                    sortable: false,
                    script: {
                        question: question,
                        example_json: exampleJson
                    }
                }];
            }
    
            const newColDefs = [...colDefs, ...newColumns];
            const newRowData = rowData.map(row => {
                const newFields = {};
                newColumns.forEach(col => {
                    newFields[col.field] = "";
                });
                return { ...row, ...newFields };
            });

            // Update state
            setColDefs(newColDefs);
            setRowData(newRowData);
            setNewColumnName('');

            // Prepare the updated data
            const updatedData = {
                columns: newColDefs.filter(col => col.field !== 'select' && col.headerName !== '#' && col.field !== undefined).map(col => ({
                    field: col.field,
                    headerName: col.headerName,
                    width: col.width,
                    script: col.headerComponentParams?.script
                })),
                rows: newRowData
            };

            // Make API call with updated data
            setIsSaving(true);
            dispatch(updateWorkspace(workspace_id, { data: updatedData }))
                .then(() => {
                    setIsSaving(false);
                    if (gridApi) {
                        gridApi.setColumnDefs(newColDefs);
                        gridApi.setRowData(newRowData);
                    }
                })
                .catch(() => setIsSaving(false));
        }
        setIsManageColumnsOpen(false);
    };

    const api_call = async (columnField) => {
        let selectedDomains;
        let selectedRowIndices = [];
        
        switch (editingColumn.selectedOptions) {
            case 'run_for_first_row':
                selectedDomains = [rowData[0].domain];
                selectedRowIndices = [0];
                break;
            case 'run_for_selected_rows':
                selectedDomains = rowData.slice(editingColumn.rowStart - 1, editingColumn.rowEnd).map(row => row.domain);
                selectedRowIndices = Array.from({length: editingColumn.rowEnd - editingColumn.rowStart + 1}, (_, i) => i + editingColumn.rowStart - 1);
                break;
            case 'run_for_all_rows':
                selectedDomains = rowData.map(row => row.domain);
                selectedRowIndices = rowData.map((_, index) => index);
                break;
            default:
                selectedDomains = rowData.map(row => row.domain);
                selectedRowIndices = rowData.map((_, index) => index);
        }
    
        const newProcessingCells = { ...processingCells };
        selectedRowIndices.forEach(rowIndex => {
            newProcessingCells[`${rowIndex}-${columnField}`] = true;
        });
        setProcessingCells(newProcessingCells);
    
        console.log("processingCells", newProcessingCells)
    
        let data = {
            "domains": selectedDomains,
            "question": editingColumn.question,
            "column_name": columnField,
            "example_json": editingColumn.exampleJson
        }
        console.log(data)
        dispatch(createSearch(workspace_id, data))

        if (gridApi) {
            gridApi.refreshCells({ force: true });
        }
    };

    const handleDeleteColumn = (fieldToDelete) => {
        // Update colDefs and rowData
        const newColDefs = colDefs.filter(col => col.field !== fieldToDelete);
        const newRowData = rowData.map(row => {
            const { [fieldToDelete]: deletedField, ...rest } = row;
            return rest;
        });

        // Update state
        setColDefs(newColDefs);
        setRowData(newRowData);
        setEditingColumn(null);
        
        // Prepare the updated data
        const updatedData = {
            columns: newColDefs.filter(col => col.field !== 'select' && col.headerName !== '#' && col.field !== undefined).map(col => ({
                field: col.field,
                headerName: col.headerName,
                width: col.width,
                script: col.headerComponentParams?.script
            })),
            rows: newRowData
        };

        // Dispatch the action to update the workspace on the server
        setIsSaving(true);
        dispatch(updateWorkspace(workspace_id, { data: updatedData }))
            .then(() => {
                setIsSaving(false);
                if (gridApi) {
                    gridApi.setColumnDefs(newColDefs);
                    gridApi.setRowData(newRowData);
                }
            })
            .catch(() => setIsSaving(false));
    };

    const handleDeleteSelectedRows = () => {
        const selectedNodes = gridApi.getSelectedNodes();
        const selectedIds = selectedNodes.map(node => node.id);
        const newRowData = rowData.filter((_, index) => !selectedIds.includes(index.toString()));
        setRowData(newRowData);
        setSelectedRowCount(0);
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        params.api.setSortModel([{ colId: 'domain', sort: 'asc' }]);
    };

    const onSelectionChanged = () => {
        if (gridApi) {
            const selectedNodes = gridApi.getSelectedNodes();
            setSelectedRowCount(selectedNodes.length);
        }
    };

    const handlePresetSelection = (preset) => {
        setSelectedPreset(preset);
        if (preset !== 'custom') {
            setNewColumnName(preset);
            setQuestion(getPresetQuestion(preset));
            setExampleJson(getPresetExampleJson(preset));
        } else {
            setNewColumnName('');
            setQuestion('');
            setExampleJson('');
        }
    };

    const getPresetQuestion = (preset) => {
        switch (preset) {
            case 'seo_score':
                return "What is the SEO score for this website?";
            case 'instagram_management_score':
                return "How well is this website's Instagram managed?";
            case 'web_design_score':
                return "What is the web design score for this website?";
            default:
                return "";
        }
    };

    const getPresetExampleJson = (preset) => {
        switch (preset) {
            case 'seo_score':
                return JSON.stringify({ score: 0, reasons: [] }, null, 2);
            case 'instagram_management_score':
                return JSON.stringify({ score: 0, reasoning: "" }, null, 2);
            case 'web_design_score':
                return JSON.stringify({ score: 0, strengths: [], weaknesses: [] }, null, 2);
            default:
                return "";
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setColumns([]);
            setSelectedDomainColumn('');
            setShowConfirmation(false);
            setShowFileUpload(false);
            setShowManageColumns(false)
            
            // Automatically move to the next step
            const formData = new FormData();
            formData.append('file', selectedFile);
            dispatch(detectColumnsFile(formData));
        }
    };

    const handleBackToFileUpload = () => {
        setShowFileUpload(true);
        setFile(null);
        setColumns([]);
        setSelectedDomainColumn('');
        setShowConfirmation(false);
    };

    const handleDomainColumnSelection = (columnName) => {
        setSelectedDomainColumn(columnName);
        setShowConfirmation(true);
    };

    const handleConfirmDomainColumn = () => {
        if (file && selectedDomainColumn) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('domain_column', selectedDomainColumn);
            formData.append('import_option', importOption);
            dispatch(importDomains(formData));
        }
        setShowConfirmation(false);
    };

    const handleUpdateColumn = () => {
        if (editingColumn) {
            const updatedColDefs = colDefs.map(col => {
                if (col.field === editingColumn.field) {
                    return {
                        ...col,
                        headerName: editingColumn.headerName,
                        colId: editingColumn.headerName,
                        width: col.actualWidth, // Add this line to preserve the current width
                        headerComponentParams: {
                            ...col.headerComponentParams,
                            script: {
                                question: editingColumn.question,
                                example_json: editingColumn.exampleJson
                            }
                        }
                    };
                }
                return col;
            });
            setColDefs(updatedColDefs);

            // Capture the current table data
            const currentTableData = captureTableData();

            // Update the column in the captured data
            const updatedColumns = currentTableData.columns.map(col => {
                if (col.field === editingColumn.field) {
                    return {
                        ...col,
                        headerName: editingColumn.headerName,
                        script: {
                            question: editingColumn.question,
                            example_json: editingColumn.exampleJson
                        }
                    };
                }
                return col;
            });

            // Prepare the updated data
            const updatedData = {
                ...currentTableData,
                columns: updatedColumns
            };

            // Dispatch the action to update the workspace on the server
            setIsSaving(true);
            dispatch(updateWorkspace(workspace_id, { data: updatedData }))
                .then(() => setIsSaving(false))
                .catch(() => setIsSaving(false));

            setEditingColumn(null);
        }
    };

    

    console.log(columns, showManageColumns)

    useEffect(() => {
        if (gridApi) {
            console.log("REFRESHING CELLS")
            gridApi.refreshCells({ force: true });
        }
    }, [processingCells]);

    const onColumnResized = (params) => {
        if (params.finished) {
            const updatedColDefs = colDefs.map(col => {
                if (col.field === params.column.getColId()) {
                    return { ...col, width: params.column.getActualWidth() };
                }
                return col;
            });
            setColDefs(updatedColDefs);
            captureTableData(); // This will save the new column widths
        }
    };

    const onCellValueChanged = (params) => {
        console.log("Cell value changed:", params);
        captureTableData();
    };

    const onColumnMoved = (event) => {
        if (event.finished) {
            console.log("Column move finished:", event);
            const newColumnState = gridApi.getColumnState();
            const newColDefs = newColumnState.map(col => {
                const columnDef = gridApi.getColumnDef(col.colId);
                return {
                    ...columnDef,
                    width: col.width
                };
            });
            setColDefs(newColDefs);
            captureTableData();
        }
    };

    const onRowDragEnd = (event) => {
        const movingNode = event.node;
        const overNode = event.overNode;

        if (overNode) {
            const newRowData = [...rowData];
            const movingData = movingNode.data;
            const fromIndex = newRowData.findIndex(item => item === movingData);
            const toIndex = newRowData.findIndex(item => item === overNode.data);

            newRowData.splice(fromIndex, 1);
            newRowData.splice(toIndex, 0, movingData);

            setRowData(newRowData);
            
            if (gridApi) {
                gridApi.refreshCells({ force: true });
                gridApi.redrawRows();
            }
            
            // Use setTimeout to ensure the state has been updated before capturing table data
            setTimeout(() => {
                captureTableData(); // This will save the rows in the new order
            }, 0);
        }
    };

    if (!workspace) {
        return <div>Loading...</div>;
    }

    return (
        <div className='h-screen w-full flex overflow-hidden bg-gray-900 text-gray-100'>
            <div className={`w-${rowData.length === 0 ? '3/4' : (isManageColumnsOpen ? '3/4' : 'full')} h-full p-4 flex w-full flex-col transition-all duration-300`}>
                <div className='flex justify-between items-center mb-4'>
                    <div className='flex items-center'>
                        <button
                            onClick={() => window.location.href = '/workspaces'}
                            className="mr-4 p-2 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded hover:from-blue-600 hover:to-purple-700 transition-all duration-300 shadow-lg shadow-blue-500/50"
                        >
                            Back
                        </button>
                        <div className='text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500'>{workspace.name}</div>
                        {isSaving && <span className="ml-4 text-blue-400">autosaving</span>}
                    </div>
                    <div className='flex items-center'>
                        {selectedRowCount > 0 && (
                            <button
                                onClick={handleDeleteSelectedRows}
                                className="mr-4 p-2 bg-gradient-to-r from-red-500 to-pink-600 text-white rounded hover:from-red-600 hover:to-pink-700 transition-all duration-300 shadow-lg shadow-red-500/50"
                            >
                                Delete Selected Rows ({selectedRowCount})
                            </button>
                        )}
                        <button
                            onClick={captureTableData}
                            className="p-2 bg-gradient-to-r from-green-500 to-teal-600 text-white rounded hover:from-green-600 hover:to-teal-700 transition-all duration-300 shadow-lg shadow-green-500/50 mr-4"
                        >
                            Capture Table Data
                        </button>
                        {rowData.length > 0 && (
                            <button
                                onClick={() => setIsManageColumnsOpen(!isManageColumnsOpen)}
                                className="p-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded hover:from-blue-600 hover:to-indigo-700 transition-all duration-300 shadow-lg shadow-blue-500/50"
                            >
                                {isManageColumnsOpen ? 'Close' : 'Open'} Create Column
                            </button>
                        )}
                    </div>
                </div>
                <div className="ag-theme-quartz-dark flex-grow">
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    onGridReady={onGridReady}
                    rowSelection="multiple"
                    onSelectionChanged={onSelectionChanged}
                    onColumnResized={onColumnResized}
                    onCellValueChanged={onCellValueChanged}
                    onColumnMoved={onColumnMoved}
                    onRowDragEnd={onRowDragEnd}
                    defaultColDef={{
                        wrapText: true,
                        autoHeight: true,
                        cellRenderer: CellRenderer,
                        cellRendererParams: {
                            processingCells: processingCells,
                            runCell: runCell
                        },
                        sortable: true,
                        filter: true, // Enable filtering for all columns by default
                        resizable: true,
                        editable: true,
                        lockPosition: false
                    }}
                    rowDragManaged={true}
                    animateRows={true}
                />
                </div>
            </div>

            
    
            {(rowData.length === 0 || isManageColumnsOpen) && (
                <div className={`${rowData.length === 0 || isManageColumnsOpen ? 'w-1/4' : 'w-0'} h-full bg-gray-800 p-4 overflow-y-auto transition-all duration-300`}>
                    {rowData.length === 0 ? (
                        <div className="flex flex-col">
                            <h2 className="text-xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">No Domains</h2>
                            <p className="text-center mb-4 text-gray-300">Please upload a CSV file to get started.</p>
                            <input
                                type="file"
                                accept=".csv"
                                onChange={handleFileChange}
                                className="mb-4 text-gray-300 bg-gray-700 rounded p-2"
                            />
                            {columns.length > 0 && (
                                <>
                                    <h3 className="text-lg font-semibold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Select Domain Column</h3>
                                    <select
                                        onChange={(e) => handleDomainColumnSelection(e.target.value)}
                                        className="p-2 bg-gray-700 text-gray-300 rounded mb-2"
                                    >
                                        <option value="">Select domain column</option>
                                        {columns.map((column, index) => (
                                            <option key={index} value={column.name}>{column.name}</option>
                                        ))}
                                    </select>
                                    {showConfirmation && (
                                        <div className="mt-4">
                                            <p className="text-gray-300">Are you sure you want to use "{selectedDomainColumn}" as the domain column?</p>
                                            <button
                                                onClick={handleConfirmDomainColumn}
                                                className="mt-2 p-2 bg-gradient-to-r from-green-500 to-teal-600 text-white rounded hover:from-green-600 hover:to-teal-700 transition-all duration-300 shadow-lg shadow-green-500/50"
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    ) : editingColumn ? (
                        <div className="flex flex-col">
                            <div onClick={() => setEditingColumn(null)} className="text-blue-400 cursor-pointer mb-4">Back</div>
                            <h2 className="text-xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Edit Column</h2>
                            <input
                                type="text"
                                value={editingColumn.headerName}
                                onChange={(e) => setEditingColumn({...editingColumn, headerName: e.target.value})}
                                placeholder="Column Name"
                                className="p-2 bg-gray-700 text-gray-300 rounded mb-2"
                            />
                            <textarea
                                value={editingColumn.question}
                                onChange={(e) => setEditingColumn({...editingColumn, question: e.target.value})}
                                placeholder="Question"
                                className="p-2 bg-gray-700 text-gray-300 rounded mb-2 h-24 resize-none"
                            />
                            <textarea
                                value={editingColumn.exampleJson}
                                onChange={(e) => setEditingColumn({...editingColumn, exampleJson: e.target.value})}
                                placeholder="Example JSON"
                                className="p-2 bg-gray-700 text-gray-300 rounded mb-2 h-32 resize-none font-mono text-sm"
                            />
                            <div className="flex flex-col mb-4">
                                <label className="flex items-center mb-2 text-gray-300">
                                    <input
                                        type="radio"
                                        name="runOption"
                                        value="run_for_first_row"
                                        checked={editingColumn.selectedOptions === 'run_for_first_row'}
                                        onChange={(e) => setEditingColumn({...editingColumn, selectedOptions: e.target.value})}
                                        className="mr-2"
                                    />
                                    Run for first row
                                </label>
                                <label className="flex items-center mb-2 text-gray-300">
                                    <input
                                        type="radio"
                                        name="runOption"
                                        value="run_for_selected_rows"
                                        checked={editingColumn.selectedOptions === 'run_for_selected_rows'}
                                        onChange={(e) => setEditingColumn({...editingColumn, selectedOptions: e.target.value})}
                                        className="mr-2"
                                    />
                                    Run for selected rows
                                </label>
                                <label className="flex items-center mb-2 text-gray-300">
                                    <input
                                        type="radio"
                                        name="runOption"
                                        value="run_for_all_rows"
                                        checked={editingColumn.selectedOptions === 'run_for_all_rows'}
                                        onChange={(e) => setEditingColumn({...editingColumn, selectedOptions: e.target.value})}
                                        className="mr-2"
                                    />
                                    Run for all rows
                                </label>
                            </div>
                            {editingColumn.selectedOptions === 'run_for_selected_rows' && (
                                <>
                                    <input
                                        type="number"
                                        placeholder="Row start"
                                        value={editingColumn.rowStart}
                                        onChange={(e) => setEditingColumn({...editingColumn, rowStart: parseInt(e.target.value)})}
                                        className="p-2 bg-gray-700 text-gray-300 rounded mb-2"
                                    />
                                    <input
                                        type="number"
                                        placeholder="Row end"
                                        value={editingColumn.rowEnd}
                                        onChange={(e) => setEditingColumn({...editingColumn, rowEnd: parseInt(e.target.value)})}
                                        className="p-2 bg-gray-700 text-gray-300 rounded mb-2"
                                    />
                                </>
                            )}
                            <button
                                onClick={handleUpdateColumn}
                                className="p-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded hover:from-blue-600 hover:to-indigo-700 transition-all duration-300 shadow-lg shadow-blue-500/50 mb-2"
                            >
                                Update Column
                            </button>
                            <button
                                onClick={() => api_call(editingColumn.field)}
                                className="p-2 bg-gradient-to-r from-green-500 to-teal-600 text-white rounded hover:from-green-600 hover:to-teal-700 transition-all duration-300 shadow-lg shadow-green-500/50 mb-2"
                            >
                                Find Data
                            </button>
                            <button
                                onClick={() => handleDeleteColumn(editingColumn.field)}
                                className="p-2 bg-gradient-to-r from-red-500 to-pink-600 text-white rounded hover:from-red-600 hover:to-pink-700 transition-all duration-300 shadow-lg shadow-red-500/50"
                            >
                                Delete Column
                            </button>
                        </div>
                    ) : (
                        <>
                            <h2 className="text-xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Create a Column</h2>
                            <div className="flex flex-col">
                                <label className="flex items-center mb-4 text-gray-300">
                                    <input
                                        type="checkbox"
                                        checked={showImportDomains}
                                        onChange={() => setShowImportDomains(!showImportDomains)}
                                        className="mr-2"
                                    />
                                    Import Domains
                                </label>

                                {showImportDomains ? (
                                    <div className="mb-4">
                                        <h3 className="text-lg font-semibold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Import Domains</h3>
                                        <input
                                            type="file"
                                            accept=".csv"
                                            onChange={handleFileChange}
                                            className="mb-4 text-gray-300 bg-gray-700 rounded p-2"
                                        />
                                        <select
                                            onChange={(e) => setImportOption(e.target.value)}
                                            className="p-2 bg-gray-700 text-gray-300 rounded mb-2 w-full"
                                        >
                                            <option value="append">Append to existing table</option>
                                            <option value="new">Create new table</option>
                                        </select>
                                        {columns.length > 0 && (
                                            <>
                                                <h4 className="text-md font-semibold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Select Domain Column</h4>
                                                <select
                                                    onChange={(e) => handleDomainColumnSelection(e.target.value)}
                                                    className="p-2 bg-gray-700 text-gray-300 rounded mb-2 w-full"
                                                >
                                                    <option value="">Select domain column</option>
                                                    {columns.map((column, index) => (
                                                        <option key={index} value={column.name}>{column.name}</option>
                                                    ))}
                                                </select>
                                                {showConfirmation && (
                                                    <div className="mt-4">
                                                        <p className="text-gray-300">Are you sure you want to use "{selectedDomainColumn}" as the domain column?</p>
                                                        <button
                                                            onClick={handleConfirmDomainColumn}
                                                            className="mt-2 p-2 bg-gradient-to-r from-green-500 to-teal-600 text-white rounded hover:from-green-600 hover:to-teal-700 transition-all duration-300 shadow-lg shadow-green-500/50 w-full"
                                                        >
                                                            Confirm
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <>
                                        <h3 className="text-lg font-semibold mb-2 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Presets</h3>
                                        <div className="grid grid-cols-2 gap-2 mb-4">
                                            {['seo_score', 'instagram_management_score', 'web_design_score', 'custom'].map((preset) => (
                                                <button
                                                    key={preset}
                                                    onClick={() => handlePresetSelection(preset)}
                                                    className={`p-2 bg-gray-700 rounded hover:bg-blue-600 hover:text-white transition-all duration-300 text-sm ${selectedPreset === preset ? 'border-2 border-blue-500 shadow-lg shadow-blue-500/50' : ''}`}
                                                >
                                                    {preset.replace(/_/g, ' ')}
                                                </button>
                                            ))}
                                        </div>
                                        {selectedPreset && (
                                            <>
                                                <input
                                                    type="text"
                                                    value={newColumnName}
                                                    onChange={(e) => setNewColumnName(e.target.value)}
                                                    placeholder="Enter new column name"
                                                    className="p-2 bg-gray-700 text-gray-300 rounded mb-2"
                                                    disabled={selectedPreset !== 'custom'}
                                                />
                                                <textarea
                                                    value={question}
                                                    onChange={(e) => setQuestion(e.target.value)}
                                                    placeholder="What would you like to find out about the domain?"
                                                    className="p-2 bg-gray-700 text-gray-300 rounded mb-2 h-24 resize-none"
                                                    disabled={selectedPreset !== 'custom'}
                                                />
                                                <textarea
                                                    value={exampleJson}
                                                    onChange={(e) => setExampleJson(e.target.value)}
                                                    placeholder="Enter example JSON for desired data structure"
                                                    className="p-2 bg-gray-700 text-gray-300 rounded mb-2 h-32 resize-none font-mono text-sm"
                                                    disabled={selectedPreset !== 'custom'}
                                                />
                                            </>
                                        )}
                                        
                                        <button
                                            onClick={handleAddColumn}
                                            className="p-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white rounded hover:from-blue-600 hover:to-indigo-700 transition-all duration-300 shadow-lg shadow-blue-500/50 mb-4"
                                        >
                                            Add Column
                                        </button>
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}

export default WorkspaceShow;