import React, { useState, useEffect } from 'react'
import Icons from './Icons'
import Label from './Label'

const Checkbox = ({ onClick, disabled=false, value, label, spacerTop, disabledText }) => {

  const revealTick = () => {
    if (value) {
      return <Icons onClick={() => onClick()} icon="tick" color="white" styles="cursor-pointer w-3 h-3"/>;
    }
  }

  const renderLabel = () => {
    if (label) {
      return (
        <div className={`${disabled ? 'opacity-50' : ""}`}>
          <Label text={label}/>
        </div>
      )
    }
  }

  const clicked = () => {
    if (disabled === false) {
      onClick()
    }
  }

  const renderDisabledText = () => {
    if (disabled) {
      return <div className="text-xs text-red-500">{disabledText}</div>  
    }
  }

  return (
    <div className={`flex items-start gap-x-2 ${spacerTop ? 'mt-4' : ''}`}>
      <div onClick={() => clicked()} className={`${disabled ? "" : "cursor-pointer"} ${disabled ? 'opacity-50' : ""} rounded-sm flex items-center justify-center ${value ? 'bg-indigo-500' : 'bg-white border'} h-5 w-5`}>
        {revealTick()}
      </div>
      <div>
        {renderLabel()}
        {renderDisabledText()}
      </div>
    </div>

  )
}

export default Checkbox;
