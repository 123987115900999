import React from 'react'
import Navbar from './Navbar'
import Icons from './Icons'
import Sidebar from './Sidebar'

const Dashboard = ({ children }) => {

  return (
    <div className="w-full h-screen flex flex-col md:flex-row">
      {/* <Sidebar/> */}
      {children}
    </div>
  )
}

export default Dashboard;
