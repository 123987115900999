import React, { useState, useEffect } from 'react';
import Scan from '../flows/domain/scan'
import Loading from '../flows/domain/loading'
import Result from '../flows/domain/result'
import CableApp from '../cable';

const Audit = () => {
    const [stage, setStage] = useState(1);

    const renderStage = () => {
        if (stage === 1) {
            return <Scan onStageChange={(i) => setStage(i)}/>
        }

        if (stage === 2) {
            return <Loading/>
        }

        if (stage === 3) {
            return <Result/>
        }
    }

    return (
        <div className='h-screen w-full flex flex-col items-center justify-center bg-gray-900 text-gray-100'>
            {renderStage()}
        </div>
    )
};

export default Audit;
