import {
  CREATE_MODEL_TRAINING_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  modelTraining: null
};

export default function modelTrainingReducer (state=INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_MODEL_TRAINING_SUCCESS:
      return { ...state, modelTraining: action.payload.model_training };
    default:
      return state;
  }
};