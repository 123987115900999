import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Input from '../components/Input'
import MultiSelect from '../components/MultiSelect'
import Button from '../components/Button'
import Icons from '../components/Icons'
import Label from '../components/Label'
import Animations from '../components/Animations'
import SearchScores from '../components/SearchScores'
import useAddOrRemoveItem from '../hooks/useAddOrRemoveItem'
import Checkbox from '../components/Checkbox'
import FadeIn from 'react-fade-in';
import { useNavigate } from "react-router-dom";
import { createSearch, checkScores, detectColumnsFile, rewriteOffer } from '../actions'
import UploadLeads from '../flows/personalisation/UploadLeads'
import SelectColumn from '../flows/personalisation/SelectColumn'
import Settings from '../flows/personalisation/Settings'

const Offer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stage, setStage] = useState(1)
  const [file, setFile] = useState(null)
  const [instructions, setInstructions] = useState('Rewrite this at a fifth grade level')
  const [inputOffer, setInputOffer] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const offer = useSelector(state => state.offerStore.offer)

  useEffect(() => {
    if (offer) {
      setSubmitting(false)
    }
  }, [offer])

  console.log(offer)

  const rewriteOfferCall = () => {
    setSubmitting(true)
    let data = {instructions: instructions, text: inputOffer}
    dispatch(rewriteOffer(data))
  }

  const renderRewrittenOffer = () => {
    if (submitting) {
      return (
        <div className="flex flex-col">
          <div className="bg-slate-300 h-6 w-full animate-pulse"></div>
          <div className="bg-slate-300 h-6 w-1/2 animate-pulse mt-4"></div>
          <div className="bg-slate-300 h-6 w-1/2 animate-pulse mt-12"></div>
          <div className="bg-slate-300 h-6 w-1/2 animate-pulse mt-4"></div>
          <div className="bg-slate-300 h-6 w-full animate-pulse mt-12"></div>
          <div className="bg-slate-300 h-6 w-48 animate-pulse mt-12"></div>
        </div>
      )
    }
    if (offer !== null) {
      return <div className="text-indigo-500 whitespace-pre-wrap">{offer.rewritten_offer}</div>
    }
    return <div className="text-slate-400">Your rewritten offer will appear here</div>
  }

  return (
    <div className="bg-white w-full h-full md:grid grid-cols-2 gap-20 justify-center p-10 mb-20 mb:0 md:p-20">
        <div className="h-full grow flex flex-col rounded-lg mb-4">
          <Label input={true} text="Paste your offer below"/>
          <textarea onChange={(e) => setInputOffer(e.target.value)} value={inputOffer} placeholder="Paster your offer" className="border p-4 bg-transparent w-full grow rounded-lg focus:outline-2 focus:outline-indigo-500"/>
          <Input spacerTop={true} onChange={(e) => setInstructions(e)} value={instructions} label="Instructions"/>
          <Button displaySpinner={submitting} onClick={() => rewriteOfferCall()} spacerTop={true} text="Rewrite my offer"/>
        </div>
      <div className="border p-4 rounded-lg bg-slate-50 ">
        {renderRewrittenOffer()}
      </div>
    </div>
  )
}

export default Offer;
