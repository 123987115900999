import React from 'react'
import Label from './Label'

const Textarea = ({ value, onChange, spacerTop, spacerBottom, label, subLabel, help, helpId, actionText, action, placeholder }) => {

  const renderLabel = () => {
    if (label) {
      return <Label action={action} actionText={actionText} subLabel={subLabel} help={help} helpId={helpId} spacerTop={spacerTop} input={true} text={label}/>
    }
  }

  return (
    <>
      {renderLabel()}
      <textarea placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} className={`p-4 min-h-24 rounded-lg focus:outline-2 focus:outline-indigo-500 filter border focus:border-none w-full ${spacerTop && label === null ? "mt-6" : ""} ${spacerBottom ? "mb-6" : ""}`}/>    
    </>
  )
}

export default Textarea;
