import {
  FETCH_WORKSPACES_SUCCESS,
  CREATE_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE_SUCCESS,
  SHOW_WORKSPACE_SUCCESS
} from '../actions/types';

const initialState = {
  workspaces: null,
  workspace: null
};

const workspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WORKSPACES_SUCCESS:
        return { ...state, workspaces: action.payload.workspaces };
    case SHOW_WORKSPACE_SUCCESS:
        return { ...state, workspace: action.payload.workspace };
    case CREATE_WORKSPACE_SUCCESS:
        return { ...state, workspace: action.payload.workspace };
    case UPDATE_WORKSPACE_SUCCESS:
        return { ...state, workspace: action.payload.workspace };
    case DELETE_WORKSPACE_SUCCESS:
        return { ...state, workspace: action.payload.workspace };
    default:
      return state;
  }
};

export default workspaceReducer;

