import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux'
import './index.css';
import Homepage from './pages/Homepage' 
import WorkspaceShow from './pages/WorkspaceShow' 
import Workspaces from './pages/Workspaces' 
import Offer from './pages/Offer' 
import Dashboard from './components/Dashboard' 
import Audit from './pages/Audit'
import AuditShow from './pages/AuditShow'
import SearchShow from './pages/SearchShow' 
import AccountShow from './pages/AccountShow'
import Welcome from './pages/Welcome'
import ToneOfVoice from './pages/ToneOfVoice' 

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path={"/"} element={<Audit/>} /> */}
        <Route path={"/"} element={<Dashboard><Homepage/></Dashboard>} />
        <Route path={"/workspaces/:workspace_id"} element={<WorkspaceShow/>} />
        <Route path={"/workspaces"} element={<Workspaces/>} />
        <Route path={"/audit/:auditId"} element={<AuditShow/>} />
        <Route path={"/offer"} element={<Dashboard><Offer/></Dashboard>} />
        <Route path={"/welcome"} element={<Welcome/>} />
        <Route path={"/tone-of-voice"} element={<Dashboard><ToneOfVoice/></Dashboard>} />
        <Route path={"/accounts/show"} element={<AccountShow/>} />
        <Route path={"/searches/:searchId"} element={<Dashboard><SearchShow/></Dashboard>} />
        <Route path={"/searches/:searchId/accounts/:accountId"} element={<AccountShow/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;