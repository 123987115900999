import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Input from '../components/Input'
import MultiSelect from '../components/MultiSelect'
import Button from '../components/Button'
import Animations from '../components/Animations'
import useAddOrRemoveItem from '../hooks/useAddOrRemoveItem'
import Checkbox from '../components/Checkbox'
import FadeIn from 'react-fade-in';
import { useNavigate, useParams } from "react-router-dom";
import { showSearch } from '../actions'
import CableApp from "../cable";

const AuditShow = () => {
  const navigate = useNavigate();
  const audit = useSelector(state => state.searchStore.audit)
  const dispatch = useDispatch();
  const [stage, setStage] = useState(null)
  const { auditId } = useParams();
  const [progress, setProgress] = useState(null)
  const [auditData, setAuditData] = useState(null)

  useEffect(() => {
    const channel = CableApp.cable.subscriptions.create(
      { channel: "SearchChannel", search_id: auditId },
      {
        connected: () => {
          console.log("Connected to SearchChannel", auditId);
        },
        received: (data) => {
            setAuditData(data)
        },
        disconnected: () => {
          console.log("Disconnected from SearchChannel", auditId);
        },
      }
    );

    // Cleanup subscription on component unmount
    return () => {
      channel.unsubscribe();
    };
  }, [auditId]);

  console.log(progress)

  if (!auditData) {
    return (
      <div className='h-screen w-full flex flex-col items-center justify-center bg-gray-900 text-gray-100'>
        <div className='text-2xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500'>
          Loading...
        </div>
      </div>
    )
  }

  return (
    <div className='h-screen w-full flex flex-col items-center justify-center bg-gray-900 text-gray-100'>
      <div className='text-2xl font-bold mb-4 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500'>
        Audit Results
      </div>
      <div className='w-full max-w-md bg-gray-800 rounded-lg shadow-lg p-6 overflow-auto'>
        <pre className='text-sm text-gray-300'>{JSON.stringify(auditData, null, 2)}</pre>
      </div>
    </div>
  )
}

export default AuditShow;
