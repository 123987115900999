import React, { useState, useEffect } from 'react'  
import { createAudit } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Scan = ({ onStageChange }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedService, setSelectedService] = useState('');
    const [domain, setDomain] = useState('');
    const audit = useSelector(state => state.searchStore.audit)
    const services = [{value: 'seo', label: 'SEO'}, {value: 'ppc', label: 'PPC'}, {value: 'web_design', label: 'Web Design'}, {value: 'instagram_management', label: 'Instagram Management'}]

    useEffect(() => {
        if (audit) {
            navigate(`/audit/${audit.id}`)
        }
    }, [audit])

    const handleServiceClick = (service) => {
        setSelectedService(selectedService === service ? '' : service);
    };

    const handleAudit = () => {
        if (!selectedService) return;
        const formattedService = `${selectedService.toLowerCase().replace(/ /g, '_')}_audit`;
        dispatch(createAudit(domain, [formattedService]))
    };

    return (
        <>
            <div className='text-2xl font-bold mb-4 text-indigo-500'>Find your next best-fit {selectedService.label} customer in seconds</div>
            <div className='grid grid-cols-2 gap-3 mb-6'>
                {services.map((service) => (
                    <button
                        key={service.value}
                        onClick={() => handleServiceClick(service)}
                        className={`p-2 bg-white rounded-lg shadow-lg text-sm transition-all duration-300 border
                            ${selectedService === service 
                                ? 'bg-indigo-500 text-white border-indigo-600' 
                                : 'hover:bg-gray-50 border-gray-200'}`}
                    >
                        <h3 className={`font-semibold ${selectedService === service 
                            ? 'text-white' 
                            : 'text-indigo-500'}`}>
                            {service.label}
                        </h3>
                    </button>
                ))}
            </div>

            <div className='w-full max-w-md'>
                <div className='flex'>
                    <input
                        type='text'
                        id='domain'
                        value={domain}
                        onChange={(e) => setDomain(e.target.value)}
                        className='flex-grow p-2 bg-white text-gray-700 border border-gray-200 rounded-l focus:outline-none focus:ring-2 focus:ring-indigo-500'
                        placeholder='example.com'
                    />
                </div>
                <button
                    onClick={handleAudit}
                    disabled={!selectedService}
                    className={`w-full mt-4 rounded-lg p-2 bg-indigo-500 text-white rounded-r transition-all duration-300 shadow-lg
                        ${!selectedService 
                            ? 'opacity-50 cursor-not-allowed' 
                            : 'hover:bg-indigo-600'}`}
                >
                    Perform Audit
                </button>
            </div>
        </>
    );
}

export default Scan
