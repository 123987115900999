import React, { useState } from 'react';

const CustomHeaderComponent = (props) => {

    // return props.displayName
  const [isOpen, setIsOpen] = useState(false);

  const onMenuClicked = () => {
    setIsOpen(!isOpen);
  };

  const onMenuItemClicked = () => {
    setIsOpen(false);
    props.onMenuItemClick();
  };

  const onHeaderClick = (item) => {
    setIsOpen(false);
    props.onHeaderClick(item);
  };

  return (
    <div onClick={onHeaderClick} className="ag-cell-label-container" role="presentation">
      <div className="ag-header-cell-label" role="presentation">
        <span>{props.displayName}</span>
        {isOpen && (
          <div className="custom-header-menu">
            {props.menuItems.map((item, index) => (
              <div key={index} className="custom-header-menu-item" onClick={() => onMenuItemClicked(item)}>
                {item}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomHeaderComponent;