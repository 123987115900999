import { combineReducers } from 'redux';
import searchReducer from './searchReducer';
import fileReducer from './fileReducer';
import workspaceReducer from './workspaceReducer';
import offerReducer from './offerReducer';
import modelTrainingReducer from './modelTrainingReducer';
import accountReducer from './accountReducer';

const appReducer = combineReducers({
  searchStore: searchReducer,
  offerStore: offerReducer,
  accountStore: accountReducer,
  modelTrainingStore: modelTrainingReducer,
  workspaceStore: workspaceReducer,
  fileStore: fileReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer;
