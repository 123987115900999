import {
  SHOW_SEARCH_SUCCESS,
  CREATE_SEARCH_SUCCESS,
  CHECK_SCORES_SUCCESS,
  CREATE_AUDIT_SUCCESS,
  PROCESS_WEBSOCKET_DATA
} from '../actions/types';

const INITIAL_STATE = {
  search: null,
  scores: null,
  audit: null
};

export default function searchReducer (state=INITIAL_STATE, action) {
  switch (action.type) {
    case CREATE_SEARCH_SUCCESS:
      return { ...state, search: action.payload.search };
    case CHECK_SCORES_SUCCESS:
      return { ...state, scores: action.payload.scores };
    case CREATE_AUDIT_SUCCESS:
      return { ...state, audit: action.payload.audit };
    case SHOW_SEARCH_SUCCESS:
      return { ...state, search: action.payload.search };
    case PROCESS_WEBSOCKET_DATA:
      return { ...state, search: action.payload.search };
    default:
      return state;
  }
};