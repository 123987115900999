import {
  SHOW_ACCOUNT,
  SHOW_ACCOUNT_SUCCESS,
  SHOW_ACCOUNT_FAIL
} from './types';
import { accountShow } from '../Backend';

export const showAccount = (searchId, accountId) => {
  return (dispatch) => {
    dispatch({ type: SHOW_ACCOUNT });
    accountShow(searchId, accountId).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          showAccountSuccess(dispatch, data);
        });
      } else {
        response.json().then((error) => {
          showAccountFail(dispatch, error);
        });
      }
    });
  };
};

const showAccountSuccess = (dispatch, data) => {
  dispatch({
    type: SHOW_ACCOUNT_SUCCESS,
    payload: data
  });
};

const showAccountFail = (dispatch, error) => {
  dispatch({
    type: SHOW_ACCOUNT_FAIL,
    payload: error
  });
};
