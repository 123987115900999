import React, { useState, useEffect } from 'react'
import { Player } from '@lottiefiles/react-lottie-player';

const Animations = ({ animation, className="h-48 w-48", loop=true, pause=false }) => {
  let src;
  const [lottieRef, setLottieRef] = useState(null)

  useEffect(() => {
    if (lottieRef) {
      if (pause) {
        lottieRef.pause()  
      }
      if (pause === false) {
        lottieRef.play()  
      }
    }
  }, [pause])

  if (animation === "scanning") {
    src = "https://assets3.lottiefiles.com/packages/lf20_gisjerg2.json"
  }

  if (animation === 'success') {
    console.log("SUCCESS ANIM STARTED")
    src = 'https://lottie.host/b1639097-8735-4170-92da-218a0e4ca653/vRiRgA0LQu.json'
    if (lottieRef) {
      setTimeout(() => {
        lottieRef.pause()  
      }, 2000)
    } 
  }

  if (animation === "btnSpinner") {
    src = "https://lottie.host/eee0a4f6-3ecb-4123-9879-1a1e6ff1b8d3/RotBEurR5i.json"
  }

  if (animation === "confetti") {
    src = "https://assets4.lottiefiles.com/packages/lf20_obhph3sh.json"
  }

  return (
    <Player
      lottieRef={instance => {setLottieRef(instance)}}
      autoplay={true}
      loop={loop}
      src={src}
      className={`${className}`}
    />
  )
  
}

export default Animations;
