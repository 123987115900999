import React from 'react'  
import Button from '../../components/Button';
import Input from '../../components/Input';
import FadeIn from 'react-fade-in';

const Services = ({ extraDetails, onExtraDetailsChange, selectedServices, onSelectedServicesChange, offer, onOfferChange, onStageChange }) => {
    const services = [{value: 'seo_audit', label: 'SEO'}, {value: 'ppc_audit', label: 'PPC'}, {value: 'web_design_audit', label: 'Web Design'}, {value: 'instagram_management_audit', label: 'Instagram Management'}]

    const handleServiceClick = (service) => {
        if (selectedServices.includes(service)) {
            onSelectedServicesChange(selectedServices.filter(s => s !== service));
        } else {
            onSelectedServicesChange([...selectedServices, service]);
        }
    };

    const renderServices = () => {
        return services.map((service) => (
            <Button 
                key={service.value}
                type={selectedServices.includes(service.value) ? 'specialGradient' : 'secondary'} 
                onClick={() => handleServiceClick(service.value)} 
                text={service.label}
            />
        ))
    }

    const getServicesText = () => {
        if (selectedServices.length === 0) return 'services';
        return selectedServices.join(' and ');
    }

    const handleExtraDetailChange = (index, value) => {
        const newExtraDetails = [...extraDetails];
        newExtraDetails[index] = value;
        onExtraDetailsChange(newExtraDetails);
    };

    const addExtraDetail = () => {
        onExtraDetailsChange([...extraDetails, '']);
    };

    const removeExtraDetail = (index) => {
        const newExtraDetails = extraDetails.filter((_, i) => i !== index);
        onExtraDetailsChange(newExtraDetails);
    };

    return (
        <FadeIn className='p-10 rounded-lg h-full flex flex-col grow'>
            <div className='text-xl font-medium mb-4 text-indigo-500'>What services do you offer?</div>
            <FadeIn delay={300} className='grid grid-cols-2 gap-3 my-6'>
                {renderServices()}
            </FadeIn>
            <div className='text-xl font-medium text-indigo-500 mt-10'>Describe your market</div>
            <div className='text-slate-400 text-sm mb-4 mt-1'>E.g. We sell to UK based travel companies.</div>
            <Input value={offer} placeholder="UK based travel companies" leadingText="We sell to..." onChange={(e) => onOfferChange(e)}/>

            <div className='text-xl font-medium text-slate-400 mt-10'>Website details<div className='inline text-slate-400'> - Optional</div></div>
            <div className='text-slate-400 text-sm mb-4 mt-1'>NB: We will scan their website for this information. E.g. They must have previous clients on their website.</div>
            <div className="space-y-2">
                {extraDetails.map((detail, index) => (
                    <div key={index} className="flex items-center gap-2 w-full">
                        <Input
                            value={detail}
                            wrapper={true}
                            placeholder="previous clients"
                            endingText="on their website"
                            leadingText="They should mention..."
                            onChange={(e) => handleExtraDetailChange(index, e)}
                        />
                        <button 
                            onClick={() => removeExtraDetail(index)}
                            className="p-2 rounded-full hover:bg-slate-100"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </div>
                ))}
                <div className='flex items-center justify-center'>
                <button 
                    onClick={addExtraDetail}
                    className="p-2 rounded-full bg-slate-100 hover:opacity-50"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                    </svg>
                </button>
                </div>
            </div>
            <Button className="mb-10" spacerTop={true} disabled={offer === '' || selectedServices.length === 0} type='primary' onClick={() => onStageChange('input')} text='Next'/>
        </FadeIn>
    );
}

export default Services
