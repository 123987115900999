import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Input from '../components/Input'
import Textarea from '../components/Textarea'
import MultiSelect from '../components/MultiSelect'
import Button from '../components/Button'
import Icons from '../components/Icons'
import Label from '../components/Label'
import Animations from '../components/Animations'
import SearchScores from '../components/SearchScores'
import useAddOrRemoveItem from '../hooks/useAddOrRemoveItem'
import Checkbox from '../components/Checkbox'
import FadeIn from 'react-fade-in';
import { useNavigate } from "react-router-dom";
import { createSearch, checkScores, detectColumnsFile, rewriteOffer, createModelTraining } from '../actions'
import UploadLeads from '../flows/personalisation/UploadLeads'
import SelectColumn from '../flows/personalisation/SelectColumn'
import Settings from '../flows/personalisation/Settings'

const ToneOfVoice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stage, setStage] = useState(1)
  const [file, setFile] = useState(null)
  const [instructions, setInstructions] = useState('Rewrite this at a third grade level')
  const [inputOffer, setInputOffer] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const offer = useSelector(state => state.offerStore.offer)
  const modelTraining = useSelector(state => state.accountStore.account)
  const modelTrained = useSelector(state => state.modelTrainingStore.modelTraining)
  const [subjectLine, setSubjectLine] = useState('')
  const [introMessage, setIntroMessage] = useState('')
  const [psLine, setPsLine] = useState('PS: ')
  const [segueLine, setSegueLine] = useState('')

  console.log(modelTraining)

  // useEffect(() => {
  //   dispatch(showUntrainedAccount())
  // }, [modelTrained])

  useEffect(() => {
  	if (modelTraining) {
  		setSubjectLine(modelTraining.account.subject_line)
    	setIntroMessage(modelTraining.account.intro_message)
    	setPsLine(modelTraining.account.ps_line)
  	}
  }, [modelTraining])

  const createModelTrainingCall = () => {
    let payload = {subject_line: subjectLine, intro_message: introMessage, ps_line: psLine, segue_line: segueLine}
    dispatch(createModelTraining(modelTraining.account.id, payload))
    setSubjectLine('')
    setIntroMessage('')
    setPsLine('PS: ')
    setSegueLine(' aside,')
  }

  if (modelTraining !== null) {
    return (
      <div className='grid grid-cols-2 p-20'>
        <div className="mx-auto m-20 overflow-auto w-full p-10 rounded-lg bg-slate-50">
          <div className="p-4 rounded-lg bg-indigo-50  text-sm">
            <div>First Name: {modelTraining.account.first_name}</div>
            <div>Handle: {modelTraining.account.handle}</div>
            <div>Last scanned at: {modelTraining.account.created_at}</div>
          </div>
          <div className="p-4 rounded-lg bg-slate-100 mt-4 text-sm">
            <div>Intro Fact: {modelTraining.account.intro_fact.fact}</div>
            <div>Fact Source: {modelTraining.account.intro_fact.key}</div>
            <div>Posted At: {modelTraining.account.intro_fact.posted_at}</div>
          </div>
          <Input value={subjectLine} onChange={(e) => setSubjectLine(e)} spacerTop={true} label="Subject Line"/>
          <hr className="my-4"/>
          <div className="p-4 rounded-lg bg-slate-100 text-sm">
            <div>Intro Fact: {modelTraining.account.intro_fact.fact}</div>
            <div>Fact Source: {modelTraining.account.intro_fact.key}</div>
            <div>Posted At: {modelTraining.account.intro_fact.posted_at}</div>
            <div>Datasource: LinkedIn</div>
            <div>Brunch Spot: {modelTraining.account.brunch_spot}</div>
          </div>
          <Input value={introMessage} onChange={(e) => setIntroMessage(e)} spacerTop={true} label="Intro Message"/>
          <hr className="my-4"/>
          <div className="p-4 rounded-lg bg-slate-100 text-sm">
            <div>Intro Fact: {modelTraining.account.intro_fact.fact}</div>
          </div>
          <Input value={segueLine} onChange={(e) => setSegueLine(e)} spacerTop={true} label="Segue Line"/>
          <hr className="my-4"/>
          <div className="p-4 rounded-lg bg-slate-100 text-sm">
            <div>PS Fact: {modelTraining.account.ps_line_fact.fact}</div>
            <div>Fact Source: {modelTraining.account.ps_line_fact.key}</div>
            <div>Posted At: {modelTraining.account.ps_line_fact.posted_at}</div>
            <div>Datasource: LinkedIn</div>
            <div>Local Park: {modelTraining.account.local_park}</div>
          </div>
          <Input value={psLine} onChange={(e) => setPsLine(e)} spacerTop={true} label="PS Line"/>
          <Button onClick={() => createModelTrainingCall()} spacerTop={true} text="Submit"/>
        </div>

        <div className="mx-auto m-20 overflow-auto w-full p-10 rounded-lg bg-slate-50">
          <div className="p-4 rounded-lg bg-indigo-50  text-sm">
            <div>First Name: {modelTraining.account.first_name}</div>
            <div>Handle: {modelTraining.account.handle}</div>
            <div>Last scanned at: {modelTraining.account.created_at}</div>
          </div>
          <div className="p-4 rounded-lg bg-slate-100 mt-4 text-sm">
            <div>Intro Fact: {modelTraining.account.intro_fact.fact}</div>
            <div>Fact Source: {modelTraining.account.intro_fact.key}</div>
            <div>Posted At: {modelTraining.account.intro_fact.posted_at}</div>
          </div>
          <Input value={subjectLine} onChange={(e) => setSubjectLine(e)} spacerTop={true} label="Subject Line"/>
          <hr className="my-4"/>
          <div className="p-4 rounded-lg bg-slate-100 text-sm">
            <div>Intro Fact: {modelTraining.account.intro_fact.fact}</div>
            <div>Fact Source: {modelTraining.account.intro_fact.key}</div>
            <div>Posted At: {modelTraining.account.intro_fact.posted_at}</div>
            <div>Datasource: LinkedIn</div>
            <div>Brunch Spot: {modelTraining.account.brunch_spot}</div>
          </div>
          <Input value={introMessage} onChange={(e) => setIntroMessage(e)} spacerTop={true} label="Intro Message"/>
          <hr className="my-4"/>
          <div className="p-4 rounded-lg bg-slate-100 text-sm">
            <div>Intro Fact: {modelTraining.account.intro_fact.fact}</div>
          </div>
          <Input value={segueLine} onChange={(e) => setSegueLine(e)} spacerTop={true} label="Segue Line"/>
          <hr className="my-4"/>
          <div className="p-4 rounded-lg bg-slate-100 text-sm">
            <div>PS Fact: {modelTraining.account.ps_line_fact.fact}</div>
            <div>Fact Source: {modelTraining.account.ps_line_fact.key}</div>
            <div>Posted At: {modelTraining.account.ps_line_fact.posted_at}</div>
            <div>Datasource: LinkedIn</div>
            <div>Local Park: {modelTraining.account.local_park}</div>
          </div>
          <Input value={psLine} onChange={(e) => setPsLine(e)} spacerTop={true} label="PS Line"/>
          <Button onClick={() => createModelTrainingCall()} spacerTop={true} text="Submit"/>
        </div>
      </div>

      
    )
  }

  return (
    <div className="bg-white w-full h-full flex flex-col justify-center">
        <div className="w-1/2 mx-auto">
          <div className="bg-indigo-50 p-4 mb-4 border border-indigo-500 rounded-lg text-center">
            <div className="text-sm text-indigo-500">Improve your personsalisation by training a model in your tone of voice.</div>
          </div>
          <div className="opacity-50 bg-slate-50 p-4 rounded-lg text-sm">
            <div className="mb-2">First Name: Charlotte</div>
            <div className="mb-2">Intro Fact: Charlotte was a finalist for Young Entrepreneur of the Year through the Great British Entrepreneur Awards</div>
            <div className="mb-2">PS Line Fact: Charlotte published an article in Philosophy Now, exploring the ethics of fat shaming.</div>
            <div className="mb-2">Local Park: Heaton Park</div>
            <div>Datasource: LinkedIn</div>
          </div>
          <hr className="my-4"/>
          <div className="opacity-50">
            <Input value="hey charlotte, you were a finalist??" label="Subject Line"/>
            <Textarea value="Hey Charlotte, I saw on your LinkedIn you were nominated as a finalist for the Great British Entrepreneur Awards, did you win??" spacerTop={true} label="Intro Message"/>
            <Input value="Awards aside," spacerTop={true} label="Segway Phrase"/>
            <Input value="PS: I saw your article on the ethics of fat shaming! Congrats on the publication :)" spacerTop={true} label="PS Line"/>
            <Button spacerTop={true} text="Train Model"/>
          </div>
        </div>
    </div>
  )
}

export default ToneOfVoice;
