import React from 'react'
// import Icons from './Icons'
// import Tooltip from './Tooltip'

const HelpTip = ({ text, anchorId="helpTip" }) => {
  return (
    // <Tooltip anchorId={anchorId} text={text}>
      {/*<Icons styles="w-4 h-4" icon="help"/>*/}
    // </Tooltip>
  )
}

export default HelpTip;
