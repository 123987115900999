import React, { useState } from 'react'
import Button from '../components/Button'
import StageIndicator from '../components/StageIndicator'
import { useNavigate } from 'react-router-dom'
import Icons from '../components/Icons'
import FadeIn from 'react-fade-in'

const Welcome = () => {
    const [stage, setStage] = useState(1)
    const [rotation, setRotation] = useState({ x: 0, y: 0 })
    const navigate = useNavigate()

    React.useEffect(() => {
        const handleMouseMove = (e) => {
            const centerX = window.innerWidth / 2
            const centerY = window.innerHeight / 2
            
            const rotateX = ((e.clientY - centerY) / centerY) * 20
            const rotateY = ((e.clientX - centerX) / centerX) * -20
            
            setRotation({ x: rotateX, y: rotateY })
        }

        window.addEventListener('mousemove', handleMouseMove)
        
        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        }
    }, [])

    const renderStageIndicator = () => {
        return (
            <div className='flex items-center gap-4 mt-10'>
                <div className={`h-4 w-4 rounded-full ${stage === 1 ? 'bg-indigo-500' : 'bg-slate-300'}`}></div>
                <div className={`h-4 w-4 rounded-full ${stage === 2 ? 'bg-indigo-500' : 'bg-slate-300'}`}></div>
                <div className={`h-4 w-4 rounded-full ${stage === 3 ? 'bg-indigo-500' : 'bg-slate-300'}`}></div>
            </div>
        )
    }

    const renderContent = () => {
        if (stage === 1) {
            return (
                <FadeIn childClassName='w-full flex justify-center'>
                    <div 
                        className='h-64 w-64 flex items-center justify-center bgGradient rounded-lg transform transition-all duration-200 ease-out'
                        style={{ 
                            transform: `perspective(1000px) rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
                        }}
                    >
                        <div className='opacity-100 text-9xl'>👋</div>
                    </div>
                    <div className='text-2xl font-medium text-indigo-500 mb-2 mt-10'>Welcome to Lead Finder</div>
                    <div className='text-center mb-10'>Our goal is to help you find your next ideal clients quickly and easily.</div>
                    <Button className={'w-1/full'} text="Next" onClick={() => setStage(2)}/>
                    {renderStageIndicator()}
                </FadeIn>
            )
        }

        if (stage === 2) {
            return (
                <FadeIn childClassName='w-full flex justify-center'>
                    <div 
                        className='h-64 w-64 flex items-center justify-center bgGradient rounded-lg transform transition-all duration-200 ease-out'
                        style={{ 
                            transform: `perspective(1000px) rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
                        }}
                    >
                        <div className='opacity-100 text-9xl'>🤔</div>
                    </div>
                    <div className='text-2xl font-medium text-indigo-500 mb-2 mt-10'>How do you find leads that are right for me?</div>
                    <div className='mb-10'>
                        1. We'll first ask you about the services you offer and the market you're targeting. <br /> 
                        2. We'll then analyze thousands of businesses based on your criteria and provide you with an overall score from 0/100 of how relevant we think the business is to you. <br /> 
                        3. Once we've found businesses we think are right for you, we'll help connect you with the best point of contact within the company based on your offer.
                    </div>
                    <div className='flex items-center gap-4 w-full'>
                        <Button type="secondary" text="Back" onClick={() => setStage(1)}/>
                        <Button text="Next" onClick={() => setStage(3)}/>
                    </div>
                    {renderStageIndicator()}
                </FadeIn>
            )
        }

        if (stage === 3) {
            return (
                <FadeIn childClassName='w-full flex justify-center'>
                    <div 
                        className='h-64 w-64 flex items-center justify-center bgGradient rounded-lg transform transition-all duration-200 ease-out'
                        style={{ 
                            transform: `perspective(1000px) rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
                        }}
                    >
                        <div className='opacity-100 text-9xl'>🤠</div>
                    </div>
                    <div className='text-2xl font-medium text-indigo-500 mb-2 mt-10'>What do I have to do?</div>
                    <div className='mb-10 text-center'>
                        We ask that you provide a list of leads that for us to analyze. You can either export these from your CRM, or use tools like Apollo.io, Clay or Sales Navigator to source a list of potential leads.
                        <br />
                        <br />
                        In a few weeks we will be able to automatically source leads for you. But for now we'll need your help.
                    </div>
                    
                    <div className='flex items-center gap-4 w-full'>
                        <Button type="secondary" text="Back" onClick={() => setStage(2)}/>
                        <Button text="Let's get started" onClick={() => navigate('/')}/>
                    </div>
                    {renderStageIndicator()}
                </FadeIn>
            )
        }


    }
    
    return (
        <div className='h-screen flex flex items-center justify-center p-20'>
            <div className='w-1/2 h-full flex flex-col items-center justify-center'>
                {renderContent()}
            </div>
            <Icons icon="abstract" className="absolute -z-10 left-0 bottom-0"/>
        </div>
    )
}

export default Welcome