import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Input from '../../components/Input'
import Button from '../../components/Button'
import BackButton from '../../components/BackButton';
import Icons from '../../components/Icons'

import FadeIn from 'react-fade-in';
import { detectColumnsFile } from '../../actions'

const UploadLeads = ({ file, email, onEmailChange, onFileChange, domain, onDomainChange, onStageChange, inputType, onInputTypeChange, onSubmit, previousClients, onPreviousClientChange }) => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false)
  const columns = useSelector(state => state.fileStore.file?.columns)
  const fileResponse = useSelector(state => state.fileStore.file)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (columns && submitting && fileResponse?.row_count > 0) {
      setSubmitting(false)
      onStageChange('selectColumn')
    }

    if (fileResponse?.row_count === 0) {
      setSubmitting(false)
      setError('row_count')
    }
  }, [columns, submitting, fileResponse])

  useEffect(() => {
    if (file && error) {
      setError(null)
    }
  }, [file])

  const uploadFile = () => {
    setSubmitting(true)
    setError(null)
    const formData = new FormData();
    formData.append('file', file);
    dispatch(detectColumnsFile(formData))
  }

  const handlePreviousClientChange = (index, value) => {
    const newPreviousClients = [...previousClients];
    newPreviousClients[index] = value;
    onPreviousClientChange(newPreviousClients);
  };

  const addPreviousClient = () => {
    onPreviousClientChange([...previousClients, '']);
  };

  const removePreviousClient = (index) => {
      const newPreviousClients = previousClients.filter((_, i) => i !== index);
      onPreviousClientChange(newPreviousClients);
  };

  const renderError = () => {
    if (error === 'row_count') {
      return (
        <div className='text-red-500 text-sm'>We didn't detect any rows. Please ensure the CSV has headings and rows.</div>
      )
    }
  }

  console.log(previousClients)

  const renderInputType = () => {
    if (inputType === 'csv') {
      return (
        <>
          <div className='w-full'> 
            <div className="text-xl text-indigo-500 font-medium mb-2">Upload CSV</div>
            <Input type="file" onChange={(e) => onFileChange(e[0])}/>
          </div>
          <Input onChange={(e) => onEmailChange(e)} placeholder="email@email.co" spacerTop={true} value={email} label="Email result to"/>
          <Button disabled={file === null} spacerTop={true} onClick={() => uploadFile()} text="Next"/> 
        </>
      )
    }

    if (inputType === 'domain') {
      return (
        <>
          <div className='w-full'> 
            <div className="text-xl text-indigo-500 font-medium mb-2">Enter a domain</div>
            <Input placeholder="google.com" onChange={(e) => onDomainChange(e)}/>
          </div>
          <Button disabled={domain?.length === 0} spacerTop={true} onClick={() => onSubmit()} text="Check Domain"/>
        </>
      )
    }

    if (inputType === 'previousClients') {
      return (
        <>
          <div className='text-xl font-medium text-slate-400 mt-10'>Previous Clients</div>
          <div className="space-y-2">
              {previousClients.length === 0 && (
                <div className="flex items-center gap-2 w-full">
                  <Input
                      value=""
                      wrapper={true}
                      placeholder="Enter Client Domain"
                      onChange={(e) => handlePreviousClientChange(0, e)}
                  />
                  <button 
                      onClick={() => removePreviousClient(0)}
                      className="p-2 rounded-full hover:bg-slate-100"
                  >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                  </button>
                </div>
              )}
              {previousClients.map((detail, index) => (
                    <div key={index} className="flex items-center gap-2 w-full">
                        <Input
                            value={detail}
                            wrapper={true}
                            placeholder="Enter Client Domain"
                            onChange={(e) => handlePreviousClientChange(index, e)}
                        />
                        <button 
                            onClick={() => removePreviousClient(index)}
                            className="p-2 rounded-full hover:bg-slate-100"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </button>
                    </div>
                ))}
                <div className='flex items-center justify-center'>
                <button 
                    onClick={addPreviousClient}
                    className="p-2 rounded-full bg-slate-100 hover:opacity-50"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-indigo-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                    </svg>
                </button>
                </div>
            </div>
            <Button disabled={previousClients?.length === 0} spacerTop={true} onClick={() => onSubmit()} text="Start Search"/>
        </>
      )
    }
  }

  return (
    <>
    
      <FadeIn className="p-10 rounded-lg grow">
        <BackButton spacerBottom={true} onClick={() => onStageChange('services')}/>
        <div className='p-4 rounded-md mb-10 bg-gradient-to-r from-indigo-100 to-indigo-300 font-medium flex justify-between items-center'>
          <div>
            <div className='text-slate-700'>Need help finding leads?</div>
            <div className='text-slate-600 mt-2 text-sm'>We can build your lead list so you can reach more potential customers.</div>
          </div>
          <div><Button onClick={() => window.open("https://www.agency-adventure.com/", "_blank")} text="Learn More" type="secondary"/></div>
        </div>
        <div className='flex gap-x-4 items-center mb-4 items-end'>
          <div className='text-xl text-indigo-500 font-medium'>2. Upload your leads</div>
        </div>
        <div className='w-full px-10 py-6 mb-10 rounded-md flex gap-x-10 bg-slate-50'>
          <div onClick={() => onInputTypeChange('csv')} className={`p-2 rounded-md w-full text-center cursor-pointer font-medium ${inputType === 'csv' ? 'bg-indigo-500 text-white' : 'text-indigo-500 bg-white'}`}>Upload CSV</div>
          <div onClick={() => onInputTypeChange('domain')} className={`p-2 rounded-md w-full text-center cursor-pointer font-medium ${inputType === 'domain' ? 'bg-indigo-500 text-white' : 'text-indigo-500 bg-white'}`}>Enter Domain</div>
          <div onClick={() => onInputTypeChange('previousClients')} className={`p-2 rounded-md w-full text-center cursor-pointer font-medium ${inputType === 'previousClients' ? 'bg-indigo-500 text-white' : 'text-indigo-500 bg-white'}`}>Previous Clients</div>
          {/* <div onClick={() => onInputTypeChange('hubspot')} className={`p-2  rounded-md w-full text-center cursor-pointer font-medium ${inputType === 'hubspot' ? 'bg-indigo-500 text-white' : 'text-indigo-500 bg-white'}`}>Connect Hubspot</div> */}
        </div>
        {renderInputType()}
      </FadeIn>
    </>
  )
}

export default UploadLeads;
