import React, { useState, useEffect } from 'react'
import Icons from './Icons'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const SearchScores = ({ loading, spacerTop, removeHandle, handles }) => {
  const scores = useSelector(state => state.searchStore.scores)
  const [visibleScores, setVisibleScores] = useState(null)

  useEffect(() => {
    if (scores) {
      setVisibleScores(scores.filter(s => handles.includes(s.handle)))  
    } else {
      setVisibleScores(null)  
    }
    
  }, [scores, handles])

  if (visibleScores === null && loading === false || visibleScores?.length === 0) {
    return null
  }

  const renderRating = (s) => {
    if (s.rating === 'green') {
      return <div className="text-green-500">We will be able to find leads from this account</div>  
    } else {
      return <div className="text-red-500">We will not be able to find leads from this account. Try a different handle or add more keywords.</div>  
    }
  }

  const renderIcon = (s) => {
    if (s.rating === 'green') {
      return <Icons className="w-5 h-5" color="green-500" icon="tick"/>
    } else {
      return <Icons className="w-5 h-5" color="red-500" icon="cross"/>
    }
  }

  const renderRemoveHandle = (s) => {
    if (s.rating === 'red') {
      return <Icons onClick={() => {removeHandle(s); setVisibleScores(visibleScores.filter(score => score.handle !== s.handle))}} className="w-4 h-4 cursor-pointer hover:opacity-50" color="slate-400" icon="cross"/>
    }
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className="flex items-center justify-between">
          <div className="w-14 h-4 bg-slate-300 animate-pulse"></div>
          <div className="w-24 h-4 bg-slate-300 animate-pulse"></div>
        </div>
      )
    }

    if (visibleScores.length > 0) {
      return visibleScores.map((s, i) => {
        return (
          <>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-x-1">
                {renderRemoveHandle(s)}
                <div>{s.handle}</div>
              </div>
              <div className='flex items-center gap-x-2'>
                {renderRating(s)}
                {renderIcon(s)}
              </div>
            </div>
            <hr className={`${visibleScores.length === i + 1 ? 'hidden' : "my-4"}`}/>
          </>
        )

      })
    }
  }

  return (
    <>
      <div className={`${spacerTop ? 'mt-4' : ''} mb-2 font-medium`}>Search score</div>
      <div className={`p-4 border bg-slate-50 text-xs rounded-lg`}>
        {renderContent()}
      </div>
    </>
  )
}

export default SearchScores;
