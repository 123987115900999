import React from 'react'
import FadeIn from 'react-fade-in';

const StageIndicator = ({ stages, stage }) => {

  const getStageIndex = (stageToCheck) => {
    return stages.findIndex(s => s.value === stageToCheck)
  }

  const getCurrentStageIndex = () => {
    return stages.findIndex(s => s.value === stage)
  }

  console.log(stages, stage)
  console.log(getCurrentStageIndex())

  const renderStages = () => {
    return stages.map((stageItem, index) => (
      <React.Fragment key={stageItem.value}>
        <div className="flex w-full flex-col">
          <div className={`h-1 w-full rounded ${getCurrentStageIndex() >= index ? 'bgGradientInverse' : 'bg-gray-200'}`}></div>
          <div className={`text-sm font-medium mt-2 capitalize ${getCurrentStageIndex() == index ? 'text-indigo-500' : 'text-slate-400'}`}>{stageItem.label}</div>
        </div>
      </React.Fragment>
    ))
  }




  return (
    <div className="w-full flex justify-between items-center gap-x-4">
        {renderStages()}
    </div>
  )
}

export default StageIndicator
