import React from 'react'
import HelpTip from './HelpTip'

const Label = ({ text, spacerBottom, input, slider, spacerTop, subLabel, help, helpId, optional }) => {

  const renderHelp = () => {
    if (help) {
      return <HelpTip anchorId={helpId} text={help}/>
    }
  }

  const renderSublabel = () => {
    if (subLabel) {
      return (
        <div className={`flex items-center gap-x-1 ${input ? 'mb-2' : ""} ${spacerBottom && !subLabel ? 'mb-6' : ''}`}>
          <div className={`text-gray-400 text-sm`}>{subLabel}</div>
          {renderHelp()}
        </div>
      )
    }
  }

  const renderOptional = () => {
    if (optional) {
      return <div className="inline-block text-lott-gray">- Optional</div>
    }
  }

  return (
    <>
      <div className={`${input ? "font-medium" : ""} text-sm ${spacerBottom && !subLabel ? 'mb-6' : ''} ${spacerTop ? 'mt-6' : ''} ${input && !subLabel ? "mb-2" : ""}`}>{text} {renderOptional()}</div>
      {renderSublabel()}
    </>
  )
}

export default Label;
