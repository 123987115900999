import {
  FETCH_WORKSPACES,
  FETCH_WORKSPACES_SUCCESS,
  FETCH_WORKSPACES_FAIL,
  CREATE_WORKSPACE,
  CREATE_WORKSPACE_SUCCESS,
  CREATE_WORKSPACE_FAIL,
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE_FAIL,
  DELETE_WORKSPACE,
  DELETE_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE_FAIL,
  SHOW_WORKSPACE,
  SHOW_WORKSPACE_SUCCESS,
  SHOW_WORKSPACE_FAIL
} from './types';
import { workspacesFetch, workspaceCreate, workspaceUpdate, workspaceDelete, workspaceShow } from '../Backend';

export const fetchWorkspaces = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_WORKSPACES });
    workspacesFetch().then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch({
            type: FETCH_WORKSPACES_SUCCESS,
            payload: data
          });
        });
      } else {
        response.json().then((error) => {
          dispatch({
            type: FETCH_WORKSPACES_FAIL,
            payload: error
          });
        });
      }
    });
  };
};

export const createWorkspace = (name) => {
  return (dispatch) => {
    dispatch({ type: CREATE_WORKSPACE });
    workspaceCreate({ name }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch({
            type: CREATE_WORKSPACE_SUCCESS,
            payload: data
          });
        });
      } else {
        response.json().then((error) => {
          dispatch({
            type: CREATE_WORKSPACE_FAIL,
            payload: error
          });
        });
      }
    });
  };
};

export const updateWorkspace = (id, data) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_WORKSPACE });
    workspaceUpdate(id, data).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch({
            type: UPDATE_WORKSPACE_SUCCESS,
            payload: data
          });
        });
      } else {
        response.json().then((error) => {
          dispatch({
            type: UPDATE_WORKSPACE_FAIL,
            payload: error
          });
        });
      }
    });
  };
};

export const deleteWorkspace = (id) => {
  return (dispatch) => {
    dispatch({ type: DELETE_WORKSPACE });
    workspaceDelete(id).then((response) => {
      if (response.ok) {
        dispatch({
          type: DELETE_WORKSPACE_SUCCESS,
          payload: id
        });
      } else {
        response.json().then((error) => {
          dispatch({
            type: DELETE_WORKSPACE_FAIL,
            payload: error
          });
        });
      }
    });
  };
};

export const showWorkspace = (id) => {
  return (dispatch) => {
    dispatch({ type: SHOW_WORKSPACE });
    workspaceShow(id).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          dispatch({
            type: SHOW_WORKSPACE_SUCCESS,
            payload: data
          });
        });
      } else {
        response.json().then((error) => {
          dispatch({
            type: SHOW_WORKSPACE_FAIL,
            payload: error
          });
        });
      }
    });
  };
};
