import {
  CREATE_MODEL_TRAINING,
  CREATE_MODEL_TRAINING_SUCCESS,
  CREATE_MODEL_TRAINING_FAIL
} from './types';
import { modelTrainingCreate } from '../Backend';

export const createModelTraining = (accountId, data, dispatch) => {
  return (dispatch) => {
    dispatch({ type: CREATE_MODEL_TRAINING });
    modelTrainingCreate(accountId, data).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          createModelTrainingSuccess(dispatch, data);
        });
      } else {
        response.json().then((error) => {
          createModelTrainingFail(dispatch, error);
        });
      }
    });
  };
};

const createModelTrainingSuccess = (dispatch, data) => {
  dispatch({
    type: CREATE_MODEL_TRAINING_SUCCESS,
    payload: data
  });
};

const createModelTrainingFail = (dispatch, error) => {
  dispatch({
    type: CREATE_MODEL_TRAINING_FAIL,
    payload: error
  });
};
