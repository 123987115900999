// SEARCHES

export const CREATE_SEARCH = 'create_search';
export const CREATE_SEARCH_SUCCESS = 'create_search_success';
export const CREATE_SEARCH_FAIL = 'create_search_fail';

export const CHECK_SCORES = 'check_scores';
export const CHECK_SCORES_SUCCESS = 'check_scores_success';
export const CHECK_SCORES_FAIL = 'check_scores_fail';

export const SHOW_SEARCH = 'show_search';
export const SHOW_SEARCH_SUCCESS = 'show_search_success';
export const SHOW_SEARCH_FAIL = 'show_search_fail';

export const IMPORT_DOMAINS = 'import_domains'
export const IMPORT_DOMAINS_SUCCESS = 'import_domains_success'
export const IMPORT_DOMAINS_FAIL = 'import_domains_fail'

// ACCOUNTS

export const SHOW_UNTRAINED_ACCOUNT = 'show_untrained_account';
export const SHOW_UNTRAINED_ACCOUNT_SUCCESS = 'show_untrained_account_success';
export const SHOW_UNTRAINED_ACCOUNT_FAIL = 'show_untrained_account_fail';

// MODEL_TRAININGS

export const CREATE_MODEL_TRAINING = 'create_model_training';
export const CREATE_MODEL_TRAINING_SUCCESS = 'create_model_training_success';
export const CREATE_MODEL_TRAINING_FAIL = 'create_model_training_fail';

// FILES

export const DETECT_COLUMNS_FILE = 'detect_columns_file'
export const DETECT_COLUMNS_FILE_SUCCESS = 'detect_columns_file_success'
export const DETECT_COLUMNS_FILE_FAIL = 'detect_columns_file_fail'

// OFFERS

export const REWRITE_OFFER = 'rewrite_offer'
export const REWRITE_OFFER_SUCCESS = 'rewrite_offer_success'
export const REWRITE_OFFER_FAIL = 'rewrite_offer_fail'

// WORKSPACES

export const FETCH_WORKSPACES = 'fetch_workspaces';
export const FETCH_WORKSPACES_SUCCESS = 'fetch_workspaces_success';
export const FETCH_WORKSPACES_FAIL = 'fetch_workspaces_fail';
export const CREATE_WORKSPACE = 'create_workspace';
export const CREATE_WORKSPACE_SUCCESS = 'create_workspace_success';
export const CREATE_WORKSPACE_FAIL = 'create_workspace_fail';
export const UPDATE_WORKSPACE = 'update_workspace';
export const UPDATE_WORKSPACE_SUCCESS = 'update_workspace_success';
export const UPDATE_WORKSPACE_FAIL = 'update_workspace_fail';
export const DELETE_WORKSPACE = 'delete_workspace';
export const DELETE_WORKSPACE_SUCCESS = 'delete_workspace_success';
export const DELETE_WORKSPACE_FAIL = 'delete_workspace_fail';
export const SHOW_WORKSPACE = 'show_workspace';
export const SHOW_WORKSPACE_SUCCESS = 'show_workspace_success';
export const SHOW_WORKSPACE_FAIL = 'show_workspace_fail';

// DOMAIN SEARCHES

export const CREATE_AUDIT = 'create_audit';
export const CREATE_AUDIT_SUCCESS = 'create_audit_success';
export const CREATE_AUDIT_FAIL = 'create_audit_fail';

// ACCOUNT

export const SHOW_ACCOUNT = 'show_account';
export const SHOW_ACCOUNT_SUCCESS = 'show_account_success';
export const SHOW_ACCOUNT_FAIL = 'show_account_fail';

export const PROCESS_WEBSOCKET_DATA = 'process_websocket_data';