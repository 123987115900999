import React from 'react'
import Icons from './Icons'

const BackButton = ({ onClick, spacerBottom=true }) => {
  return (
    <div className={`flex items-center gap-x-2 opacity-80 hover:opacity-100 ${spacerBottom ? "mb-4" : ""}`}>
      <div onClick={onClick} className="cursor-pointer text-slate-800 hover:text-indigo-500 w-min text-sm font-medium whitespace-pre">← Back</div>
    </div>
  )
}

export default BackButton;
