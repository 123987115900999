import {
  DETECT_COLUMNS_FILE_SUCCESS,
  IMPORT_DOMAINS_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  file: null,
  columns: null,
  domains: null
};

export default function fileReducer (state=INITIAL_STATE, action) {
  switch (action.type) {
    case DETECT_COLUMNS_FILE_SUCCESS:
      return { ...state, file: action.payload.file };
      case IMPORT_DOMAINS_SUCCESS:
        return { ...state, domains: action.payload.domains };
    default:
      return state;
  }
};