import React from 'react'
import Icons from './Icons'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.userStore.user)

  const renderAvatar = () => {
    if (user.avatar_url !== null) {
      return <img onClick={() => navigate(`/users/${user.id}`)} src={user.avatar_url} className="object-cover w-12 h-12 rounded-full cursor-pointer hover:opacity-80 hover:rotate-12"/>
    } else {
      return <div onClick={() => navigate(`/users/${user.id}`)} className="cursor-pointer hover:opacity-80 transition-all hover:rotate-12 bg-orange-500 w-12 h-12 p-4 rounded-full font-bold items-center justify-center flex text-xl">{user.first_name[0]}</div>
    }
  }

  if (user) {
    return (
      <div className="flex justify-between mb-10 items-center">
        <div onClick={() => navigate('/links')} className="cursor-pointer hover:opacity-80 flex gap-x-4">
          <Icons className="object-fit h-6 w-6 mx-auto rotate-12" icon="coffee"/>
          <div className="hidden md:block font-semibold text-2xl text-indigo-500">HOT COFFEE</div>
        </div>
        {renderAvatar()}
      </div>
    )
  }

  return (
    <div className="flex items-center mb-10 items-center">
      <div className="flex items-center gap-x-4">
        <Icons className="object-fit h-6 w-6 mx-auto rotate-12" icon="coffee"/>
        <div className="hidden md:block font-semibold text-2xl text-indigo-500">HOT COFFEE</div>
      </div>
    </div>
  )
}

export default Navbar;
