import React, { useState } from 'react'

const useAddOrRemoveItem = () => {
  const [items, setItems] = useState()

  const processFunction = (array, item, hashMode=false) => {
    console.log("HERE", array, item)
    if (array.includes(item)) {
      let removedItemArray = array.filter((arrayItem) => {
        return arrayItem !== item
      })
      return removedItemArray
    } else {
      return [...array, item]
    }
  }

  return [processFunction]
}

export default useAddOrRemoveItem;