import React from 'react'
import Label from './Label'

const Input = ({ inputRef, leadingText=null, endingText=null, wrapper=false, type="text", className, placeholder, label, error, errorMessage, helpId, subLabel, onChange, value, spacerBottom, spacerTop, onKeyUp, help, optional }) => {

  const renderLabel = () => {
    if (label) {
      return <Label optional={optional} help={help} helpId={helpId} subLabel={subLabel} spacerTop={spacerTop} input={true} text={label}/>
    }
  }

  const renderError = () => {
    if (error) {
      return <div className="text-sm text-red-500">{errorMessage}</div>
    }
  }

  const renderLeadingText = () => {
    if (leadingText) {
      return <div className="whitespace-pre h-full py-2 bg-slate-50 px-2 rounded-lg">{leadingText}</div>
    }
  }

  const renderEndingText = () => {
    if (endingText) {
      return <div className="whitespace-pre h-full py-2 bg-slate-50 px-2 rounded-lg">{endingText}</div>
    }
  }

  if (type === 'file') {
    return (
      <>
        {renderLabel()}
        <input accept=".csv" onChange={(e) => onChange(e.target.files)} type="file" ref={inputRef} className={`${className} focus:outline-2 focus:outline-indigo-500 text-sm ${spacerBottom ? "mb-4" : ""} ${spacerTop && !label ? 'mt-4' : ""}`}/>
        {renderError()}
      </>
    )
  }

  return (
    <div className={`${wrapper ? 'w-full' : ''}`}>
      {renderLabel()}
      <div className='flex items-center gap-x-2'>
        {renderLeadingText()}
        <input placeholder={placeholder} ref={inputRef} className={`${className} rounded-lg  w-full bg-white px-5 py-2 focus:outline-2 focus:outline-indigo-500 filter border focus:border-none ${spacerBottom ? "mb-10" : ""} ${spacerTop && !label ? 'mt-10' : ""}`} onKeyUp={(e) => {onKeyUp ? onKeyUp(e) : console.log()}} onChange={(e) => onChange(e.target.value)} type={type} value={value}/>
        {renderEndingText()}
      </div>
      {renderError()}
    </div>
  )
}

export default Input;
