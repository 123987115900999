import {
  DETECT_COLUMNS_FILE,
  DETECT_COLUMNS_FILE_SUCCESS,
  DETECT_COLUMNS_FILE_FAIL,
  IMPORT_DOMAINS,
  IMPORT_DOMAINS_SUCCESS,
  IMPORT_DOMAINS_FAIL,
} from './types';
import { fileDetectColumns, fileImportDomains } from '../Backend';

export const detectColumnsFile = (data, dispatch) => {
  return (dispatch) => {
    dispatch({ type: DETECT_COLUMNS_FILE });
    fileDetectColumns(data).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          detectColumnsFileSuccess(dispatch, data);
        });
      } else {
        response.json().then((error) => {
          detectColumnsFileFail(dispatch, error);
        });
      }
    });
  };
};

const detectColumnsFileSuccess = (dispatch, data) => {
  dispatch({
    type: DETECT_COLUMNS_FILE_SUCCESS,
    payload: data
  });
};

const detectColumnsFileFail = (dispatch, error) => {
  dispatch({
    type: DETECT_COLUMNS_FILE_FAIL,
    payload: error
  });
};

export const importDomains = (data, dispatch) => {
  return (dispatch) => {
    dispatch({ type: IMPORT_DOMAINS });
    fileImportDomains(data).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          importDomainsSuccess(dispatch, data);
        });
      } else {
        response.json().then((error) => {
          importDomainsFail(dispatch, error);
        });
      }
    });
  };
};

const importDomainsSuccess = (dispatch, data) => {
  dispatch({
    type: IMPORT_DOMAINS_SUCCESS,
    payload: data
  });
};

const importDomainsFail = (dispatch, error) => {
  dispatch({
    type: IMPORT_DOMAINS_FAIL,
    payload: error
  });
};
