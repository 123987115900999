import React from 'react'
import { tailwindColors } from '../helpers/tailwindHelper'

const ClassToHex = (className) => {

  if (className === undefined) {
    return null     
  } else {
    if (className.includes("-")) {
      let formattedClassName = className.split("-")
      return tailwindColors()[formattedClassName[0]][formattedClassName[1]]
    } else {
      return tailwindColors()[className]
    }
    
  }
  
}

export default ClassToHex;