import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Input from '../components/Input'
import MultiSelect from '../components/MultiSelect'
import Button from '../components/Button'
import Icons from '../components/Icons'
import Animations from '../components/Animations'
import StageIndicator from '../components/StageIndicator'
import SearchScores from '../components/SearchScores'
import useAddOrRemoveItem from '../hooks/useAddOrRemoveItem'
import Checkbox from '../components/Checkbox'
import FadeIn from 'react-fade-in';
import { useNavigate } from "react-router-dom";
import { createSearch, checkScores, detectColumnsFile } from '../actions'
import UploadLeads from '../flows/personalisation/UploadLeads'
import Services from '../flows/personalisation/Services'
import SelectColumn from '../flows/personalisation/SelectColumn'
import Settings from '../flows/personalisation/Settings'

const Homepage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stage, setStage] = useState('services')
  const [file, setFile] = useState(null)
  const [leadColumn, setLeadColumn] = useState(null)
  const [domain, setDomain] = useState('')
  const search = useSelector(state => state.searchStore.search)
  const [inputType, setInputType] = useState('csv')
  const [selectedServices, setSelectedServices] = useState([])
  const [offer, setOffer] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [email, setEmail] = useState('')
  const [extraDetails, setExtraDetails] = useState([])
  const [previousClients, setPreviousClients] = useState([])

  console.log(search)

  const stages = [{value: 'services', label: '1. Describe offer'}, {value: 'input', label: '2. Upload Leads'}]

  useEffect(() => {
    if (search !== null) {
      navigate(`/searches/${search.id}`)
    }
  }, [search])

  console.log('extraDetails', extraDetails)

  console.log('selectedServices', selectedServices)

  const submitSearch = () => {
    setSubmitting(true)
    const formData = new FormData();
    formData.append('search[file]', file);
    formData.append('search[domain]', domain)
    formData.append('search[domain_column]', leadColumn?.name);
    formData.append('search[email_results_to]', email);
    formData.append('search[find_best_contact]', true);
    formData.append('search[services]', JSON.stringify(selectedServices));
    formData.append('search[market]', offer);
    formData.append('search[input_type]', inputType === 'previousClients' ? 'previous_clients' : inputType);
    formData.append('search[extra_details]', JSON.stringify(extraDetails));
    formData.append('search[previous_clients]', JSON.stringify(previousClients));
    dispatch(createSearch(formData))
  }

  const renderStage = () => {
    if (stage === 'services') {
      return <Services extraDetails={extraDetails} onExtraDetailsChange={(e) => setExtraDetails(e)} selectedServices={selectedServices} onSelectedServicesChange={(i) => setSelectedServices(i)} offer={offer} onOfferChange={(e) => setOffer(e)} onStageChange={(i) => setStage(i)}/>
    }

    if (stage === 'input') {
      return <UploadLeads previousClients={previousClients} onPreviousClientChange={(e) => setPreviousClients(e)} onSubmit={() => submitSearch()} submitting={submitting} email={email} onEmailChange={(e) => setEmail(e)} domain={domain} onDomainChange={(e) => setDomain(e)} onInputTypeChange={(i) => setInputType(i)} inputType={inputType} file={file} onFileChange={(e) => setFile(e)} onStageChange={(i) => setStage(i)}/>
    }

    if (stage === 'selectColumn') {
      return <SelectColumn onSubmit={() => submitSearch()} submitting={submitting} leadColumn={leadColumn} onLeadColumnChange={(i) => setLeadColumn(i)} file={file} onStageChange={(i) => setStage(i)}/>
    }
  }

  return (
      <div className="flex flex-col w-3/4 mx-auto">
        <div className='mt-20 mb-10 font-medium text-2xl text-indigo-500'>Find your next ideal client in under 30 seconds</div>
        <div className='w-full mx-auto'>
          <StageIndicator stages={stages} stage={stage == 'selectColumn' ? 'input' : stage}/>
        </div>
        <div id="stage" className='mt-20 overflow-y-auto mb-20 grow border rounded-lg'>
          {renderStage()}
        </div>
      </div>
  )
}

export default Homepage;
