const environment = () => {
  if (process.env.REACT_APP_NODE_ENV === 'staging') {
    return "staging";
  }
  return process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV
}

const backendURL = () => {
  if (environment() === "development") {
    return 'http://localhost:3000'
  }
  if (environment() === "staging") {
    return ''
  }
  if (environment() === "production") {
    return 'https://instagram-lead-finder-api-8eb56eec4b9e.herokuapp.com'
  }
}

const appsignalKey = () => {
  if (environment() === "staging") {
    return "";
  }
  if (environment() === "production") {
    return "";
  }
}

export const ENVIRONMENT = environment();
export const BACKEND_URL = backendURL();