import {
  CREATE_SEARCH,
  CREATE_SEARCH_SUCCESS,
  CREATE_SEARCH_FAIL,
  CHECK_SCORES,
  CHECK_SCORES_SUCCESS,
  CHECK_SCORES_FAIL,
  SHOW_SEARCH,
  SHOW_SEARCH_SUCCESS,
  SHOW_SEARCH_FAIL,
  CREATE_AUDIT,
  CREATE_AUDIT_SUCCESS,
  CREATE_AUDIT_FAIL,
  PROCESS_WEBSOCKET_DATA
} from './types';
import { searchCreate, searchShow, scoresCheck, auditCreate } from '../Backend';

  export const processWebsocketData = (data) => {
    return (dispatch) => {
      dispatch({ type: PROCESS_WEBSOCKET_DATA, payload: data });
    };
  };

  export const createSearch = (data, dispatch) => {
    return (dispatch) => {
      dispatch({ type: CREATE_SEARCH });
      searchCreate(data).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            createSearchSuccess(dispatch, data);
          });
        } else {
          response.json().then((error) => {
            createSearchFail(dispatch, error);
          });
        }
      });
    };
  };

  const createSearchSuccess = (dispatch, data) => {
    dispatch({
      type: CREATE_SEARCH_SUCCESS,
      payload: data
    });
  };

  const createSearchFail = (dispatch, error) => {
    dispatch({
      type: CREATE_SEARCH_FAIL,
      payload: error
    });
  };

export const createAudit = (domain, service) => {
  return (dispatch) => {
    dispatch({ type: CREATE_AUDIT });
    auditCreate(domain, service).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          createAuditSuccess(dispatch, data);
        });
      } else {
        response.json().then((error) => {
          createAuditFail(dispatch, error);
        });
      }
    });
  };
};

const createAuditSuccess = (dispatch, data) => {
  dispatch({
    type: CREATE_AUDIT_SUCCESS,
    payload: data
  });
};

const createAuditFail = (dispatch, error) => {
  dispatch({
    type: CREATE_AUDIT_FAIL,
    payload: error
  });
};

export const checkScores = (data, dispatch) => {
  return (dispatch) => {
    dispatch({ type: CHECK_SCORES });
    scoresCheck(data).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          checkScoresSuccess(dispatch, data);
        });
      } else {
        response.json().then((error) => {
          checkScoresFail(dispatch, error);
        });
      }
    });
  };
};

const checkScoresSuccess = (dispatch, data) => {
  dispatch({
    type: CHECK_SCORES_SUCCESS,
    payload: data
  });
};

const checkScoresFail = (dispatch, error) => {
  dispatch({
    type: CHECK_SCORES_FAIL,
    payload: error
  });
};

export const showSearch = (data, dispatch) => {
  return (dispatch) => {
    dispatch({ type: SHOW_SEARCH });
    searchShow(data).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          showSearchSuccess(dispatch, data);
        });
      } else {
        response.json().then((error) => {
          showSearchFail(dispatch, error);
        });
      }
    });
  };
};

const showSearchSuccess = (dispatch, data) => {
  dispatch({
    type: SHOW_SEARCH_SUCCESS,
    payload: data
  });
};

const showSearchFail = (dispatch, error) => {
  dispatch({
    type: SHOW_SEARCH_FAIL,
    payload: error
  });
};