import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Input from '../components/Input'
import MultiSelect from '../components/MultiSelect'
import Button from '../components/Button'
import AccountsTable from '../components/AccountsTable'
import Animations from '../components/Animations'
import useAddOrRemoveItem from '../hooks/useAddOrRemoveItem'
import Checkbox from '../components/Checkbox'
import FadeIn from 'react-fade-in';
import { useNavigate, useParams } from "react-router-dom";
import { showSearch, processWebsocketData } from '../actions'
import CableApp from "../cable";

const SearchShow = () => {
  const navigate = useNavigate();
  const search = useSelector(state => state.searchStore.search)
  const dispatch = useDispatch();
  const [stage, setStage] = useState(null)
  const { searchId } = useParams();
  // const [progress, setProgress] = useState(null)

  useEffect(() => {
    const channel = CableApp.cable.subscriptions.create(
      { channel: "SearchChannel", search_id: searchId },
      {
        connected: () => {
          console.log("Connected to SearchChannel", searchId);
        },
        received: (data) => {
          console.log("Received data", data)
          // setProgress(data)
          
          dispatch(processWebsocketData(data))
        },
        disconnected: () => {
          console.log("Disconnected from SearchChannel", searchId);
        },
      }
    );
    // Cleanup subscription on component unmount
    return () => {
      channel.unsubscribe();
    };
  }, [searchId]);

  useEffect(() => {
    if (search === null) {
      dispatch(showSearch(searchId))
    } else {
      // search.processing_complete ? setStage(2) : setStage(1)
    }

  }, [search])

  console.log(search)

  // useEffect(() => {
  //   if (progress?.search?.status == 'complete' && progress?.search?.input_type == 'domain') {
  //     navigate(`/searches/${progress?.search?.id}/accounts/${progress?.accounts[0].id}`)
  //   }
  // }, [progress])

  // // useEffect(() => {
  // //   if (progress?.step == 'complete') {
  // //     dispatch(showSearch(searchId))
  // //   }
  // // }, [progress])

  // const renderPersonalisationProgress = () => {
  //   if (progress) {
  //     return (
  //       <div className="bg-slate-50 w-full p-4 rounded-lg mt-4 flex flex-col items-center">
  //         <div className="flex gap-x-2 items-center w-52 justify-between">
  //           <div className="text-slate-600 text-sm">Time Remaining: </div>
  //           <div className="text-indigo-500 text-sm">{progress.time_remaining}</div>
  //         </div>
  //         <div className="flex gap-x-2 items-center w-52 justify-between mt-1">
  //           <div className="text-slate-600 text-sm">Leads Processed: </div>
  //           <div className="text-indigo-500 text-sm">{progress.processed_leads} / {progress.total_leads}</div>
  //         </div>
  //       </div>
  //     )  
  //   }

  //   return (
  //     <div className="bg-slate-50 w-full p-4 rounded-lg mt-4 flex flex-col items-center">
  //         <div className="flex gap-x-2 items-center w-52 justify-between">
  //           <div className="text-slate-600 text-sm">Time Remaining: </div>
  //           <div className="text-indigo-500 text-sm animate-pulse">Calculating...</div>
  //         </div>
  //         <div className="flex gap-x-2 items-center w-52 justify-between mt-1">
  //           <div className="text-slate-600 text-sm">Leads Processed: </div>
  //           <div className="text-indigo-500 text-sm animate-pulse">Calculating...</div>
  //         </div>
  //       </div>
  //   )
    
  // }

  // const renderAction = () => {
  //   if (search.create_basic_personalisation) {
  //     return (
  //       <div className="flex items-center gap-x-4 w-full">
  //         <Button type="secondary" onClick={() => window.open(search.basic_internal_url, "_blank")} disabled={search.basic_internal_url === null} text="Download Basic CSV"/>
  //         <Button onClick={() => window.open(search.internal_url, "_blank")} disabled={search.internal_url === null} text="Download Advanced CSV"/>
  //       </div>
  //     )
  //   }

  //   return <Button onClick={() => window.open(search.internal_url, "_blank")} disabled={search.internal_url === null} text="Download Personalised CSV"/>
  // }


  // const renderStage = () => {
  //   if (stage === 1) {
  //     return (
  //       <>
  //         <Animations loop={true} animation="scanning"/>
  //         <hr className="my-4 border-slate-100 border-2 w-full" />
  //         <div className="text-indigo-500">We are personalising your leads</div>
  //         {renderPersonalisationProgress()}
  //       </>
  //     )
  //   }

  //   if (stage === 2) {
  //     console.log("STAGE 2 runs")
  //     return (
  //       <>
  //         <Animations loop={false} animation="success"/>
  //         {/* <div className="p-4 rounded-lg bg-indigo-100 w-full">
  //           <div className='text-center text-indigo-500'>File received, thank you team! </div>
  //         </div> */}
  //         <hr className="my-4 border-slate-100 border-2 w-full" />
  //         <div className='font-medium text-center text-indigo-500'>File received, thank you team! </div>
  //         {/* {renderAction()} */}
  //       </>
  //     )
  //   }
  // }

  // if (search === null) {
  //   return null
  // }

  // return (
  //   <div className='h-screen w-1/2 mx-auto h-full flex flex-col grow justify-center items-center'>
  //     {renderStage()}
  //   </div>
  // )



  // if (true) {
  //   return <div>Loading...</div>
  // }

  const renderStatusBanner = () => {
    if (search === null) {
      return null
    }

    if (search.status == 'processing') {
      return (
      <div className="text-center bg-indigo-100 p-4 rounded-lg w-full mb-10">
        <div>Please wait while we process your leads</div>
      </div>
      )
    }

    if (search.status == 'complete') {
      return (
      <div className="text-center bg-indigo-100 p-4 rounded-lg w-full mb-10">
        <div>Your leads have been processed. You can download the table as a CSV or click on a lead to learn more.</div>
      </div>
      )
    }
  }

  return (
    <div className='w-1/2 mx-auto h-full flex flex-col grow items-center p-10'>
      {renderStatusBanner()}
      <AccountsTable spacerTop={true}/>
    </div>
  )
}

export default SearchShow;
