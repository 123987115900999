import React, { useEffect, useState } from 'react'
import Icons from './Icons'
import Button from './Button'
import { useNavigate } from 'react-router-dom'
import BackButton from './BackButton'
import { useSelector } from 'react-redux'

const AccountsTable = ({ spacerTop=false }) => {
    const search = useSelector(state => state.searchStore.search)
    const [service, setService] = useState(null)
    const [accounts, setAccounts] = useState(null)
    const navigate = useNavigate()

    console.log('search!!', search)

    useEffect(() => {
        if (search) {
            setService(search.services[0])
            setAccounts(search.accounts)
        }
    }, [search])

    const renderProcessingRows = () => {
        if (search.status == 'processing') {
            return (
                <>
                    <tr><td colSpan="100%" className='h-10 bg-slate-200 w-full animate-pulse'></td></tr>
                    <tr><td colSpan="100%" className='h-10 bg-slate-200 w-full animate-pulse'></td></tr>
                    <tr><td colSpan="100%" className='h-10 bg-slate-200 w-full animate-pulse'></td></tr>
                    <tr><td colSpan="100%" className='h-10 bg-slate-200 w-full animate-pulse'></td></tr>
                </>
            )
        }
    }

    const calculateRating = (score) => {
        if (score >= 75) {
            return '🌶️🌶️🌶️'
        } else if (score >= 50) {
            return '🌶️🌶️'
        }
    }

    const downloadCSV = () => {
        window.open(search.s3_url, '_blank')
    }

    
    const renderRows = () => {
        if (accounts?.length === 0 || accounts === undefined) {
            return (
                <>
                    <tr><td colSpan="100%" className='bg-slate-200 h-10 w-full animate-pulse'></td></tr>
                    <tr><td colSpan="100%" className='bg-slate-200 h-10 w-full animate-pulse'></td></tr>
                    <tr><td colSpan="100%" className='bg-slate-200 h-10 w-full animate-pulse'></td></tr>
                    <tr><td colSpan="100%" className='bg-slate-200 h-10 w-full animate-pulse'></td></tr>
                    <tr><td colSpan="100%" className='bg-slate-200 h-10 w-full animate-pulse'></td></tr>
                    <tr><td colSpan="100%" className='bg-slate-200 h-10 w-full animate-pulse'></td></tr>
                    <tr><td colSpan="100%" className='bg-slate-200 h-10 w-full animate-pulse'></td></tr>
                    <tr><td colSpan="100%" className='bg-slate-200 h-10 w-full animate-pulse'></td></tr>
                    <tr><td colSpan="100%" className='bg-slate-200 h-10 w-full animate-pulse'></td></tr>
                </>
            )
        } else {
            return search.table.rows.map((row, i) => (
                <tr onClick={() => navigate(`/accounts/show`)} key={i} className="h-10 hover:bg-slate-100 cursor-pointer">
                    {row.map((cell, j) => (
                        <td 
                            key={j}
                            className={`px-6 py-4 text-sm text-slate-900 overflow-hidden ${
                                // Allow text to wrap for reasoning columns which are typically longer
                                // j === 5 || j === 7 ? 'whitespace-normal' : 'whitespace-nowrap'
                                'whitespace-nowrap'
                            }`}
                        >
                            {cell}
                        </td>
                    ))}
                </tr>
            ))
        }
    }

    if (search === null) {
        return null
    }

    console.log('search', search)

    if (search) {
        return (
            <>
                <div className='flex justify-between w-full'>
                    <BackButton onClick={() => window.location.href = '/'}/>
                    <Button onClick={() => downloadCSV()} disabled={search.s3_url === null} width="w-min whitespace-pre" text="Download CSV"/>
                </div>
                <div className={`w-full overflow-x-auto overscroll-behavior-x-contain border-2 border-slate-200 rounded-lg shadow-sm ${spacerTop ? "mt-4" : ""}`}>
                    <table className="min-w-full divide-y divide-slate-200">
                        <thead className="bg-slate-50">
                            <tr>
                                {search.table.headers.map((header, i) => (
                                    <th
                                        key={i}
                                        scope="col"
                                        className="px-6 py-3 align-top text-left text-xs font-medium text-slate-500 uppercase tracking-wider whitespace-nowrap"
                                    >
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody  className="bg-white divide-y divide-slate-200 align-top">
                            {renderRows()}
                            {/* {renderProcessingRows()} */}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

  
}

export default AccountsTable;
