import {
  REWRITE_OFFER_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  offer: null
};

export default function offerReducer (state=INITIAL_STATE, action) {
  switch (action.type) {
    case REWRITE_OFFER_SUCCESS:
      return { ...state, offer: action.payload.offer };
    default:
      return state;
  }
};