import {
  SHOW_ACCOUNT_SUCCESS
} from '../actions/types';

const INITIAL_STATE = {
  account: null
};

export default function accountReducer (state=INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_ACCOUNT_SUCCESS:
      return { ...state, account: action.payload.account };
    default:
      return state;
  }
};