import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import Button from '../../components/Button'
import Icons from '../../components/Icons'
import Checkbox from '../../components/Checkbox'
import BackButton from '../../components/BackButton'
import { midTruncate } from '../../interfaceHelpers'

const SelectColumn = ({ file, onStageChange, leadColumn, onLeadColumnChange, onSubmit, submitting }) => {
  const columns = useSelector(state => state.fileStore.file?.columns)
  const [allColumnsVisible, setAllColumnsVisible] = useState(false)
  const [warningConfirmed, setWarningConfirmed] = useState(false)
  const domainColumns = columns.filter(col => col.contains_domain_data)
  const emailColumns = columns.filter(col => col.contains_email_data)
  const otherColumns = columns.filter(col => !col.contains_domain_data && !col.contains_email_data)
  const [otherColumnDisplay, setOtherColumnDisplay] = useState(null)

  useEffect(() => {
    if (domainColumns.length === 0) {
      setAllColumnsVisible(true)
    }
  }, [domainColumns])

  const renderHeading = () => {
    return <div className='text-xl text-indigo-500'>Select the column containing the "Domain" or "Email Address"</div>
  }

  const renderSubHeading = () => {
    let text;

    if (domainColumns.length === 0) {
      text = "We could not detect any columns which contain the correct data. Please ensure your CSV contains a domain column"
      return <div className='text-slate-600 text-sm mb-4'>{text}</div>  
    }

    if (domainColumns.length === 1) {
      text = "We've detected the column below could be correct."
      return <div className='text-slate-600 text-sm mb-4'>{text}</div>  
    }

    if (domainColumns.length > 1) {
      text = `We've detected ${domainColumns.length} possible columns, please select the correct one`
      return <div className='text-slate-600 text-sm mb-4'>{text}</div>  
    }
    
  }
  
  const renderdomainColumns = () => {
    return domainColumns.map((i, index) => {
      return (
        <div key={i.name} onClick={() => onLeadColumnChange(i)} className={`flex justify-between items-center p-4 ${domainColumns.length -1 === index ? '' : 'mb-4'} rounded-lg cursor-pointer hover:opacity-50 ${leadColumn?.name.length > 0 && leadColumn.name === i.name ? "border-indigo-500 bg-indigo-50 border" : "bg-slate-50 border-slate-300"}`}>
          <div>{i.name}</div>
          <div>
            {renderSamples(i.samples)}
          </div>
        </div>
      )
    })
  }

  const renderEmailColumns = () => {
    return emailColumns.map((i, index) => {
      return (
        <div key={i.name} onClick={() => onLeadColumnChange(i)} className={`flex justify-between items-center p-4 ${emailColumns.length -1 === index ? '' : 'mb-4'} rounded-lg cursor-pointer hover:opacity-50 ${leadColumn?.name.length > 0 && leadColumn.name === i.name ? "border-indigo-500 bg-indigo-50 border" : "bg-slate-50 border-slate-300"}`}>
          <div>{i.name}</div>
          <div>
            {renderSamples(i.samples)}
          </div>
        </div>
      )
    })
  }

  const renderSamples = (samples) => {
    return samples.map((i, index) => {
      return <div key={index} className="w-48 truncate text-xs text-right">{i}</div>
    })
  }

  const renderNonEmailColumns = () => {
    if (otherColumnDisplay === 'email') {
    return otherColumns.map((i, index) => {
      return (
        <div key={i.name} onClick={() => onLeadColumnChange(i)} className={`flex justify-between  items-center p-4 mb-4 rounded-lg bg-slate-50 cursor-pointer hover:opacity-50 ${leadColumn?.name.length > 0 && leadColumn.name === i.name ? "border-indigo-500 bg-indigo-50 border" : "border-slate-300"}`}>
          <div>{i.name}</div>
          <div >
            {renderSamples(i.samples)}
          </div>
        </div>
      )
      })
    }
  }

  const renderNondomainColumns = () => {
    if (otherColumnDisplay === 'domain') {
    return otherColumns.map((i, index) => {
      return (
        <div key={i.name} onClick={() => onLeadColumnChange(i)} className={`flex justify-between  items-center p-4 mb-4 rounded-lg bg-slate-50 cursor-pointer hover:opacity-50 ${leadColumn?.name.length > 0 && leadColumn.name === i.name ? "border-indigo-500 bg-indigo-50 border" : "border-slate-300"}`}>
          <div>{i.name}</div>
          <div >
            {renderSamples(i.samples)}
          </div>
        </div>
        )
      })
    }
  }

  const renderdomainColumnsSection = () => {
    if (domainColumns.length > 0) {
      return (
        <>
          <div className="flex items-center mb-2 justify-between">
            <div className="text-sm text-slate-600">Domain Column Name</div>
            <div className="text-sm text-slate-600">Domain Data</div>
          </div>
          {renderdomainColumns()}
        </>
      )
    }
  }

  const renderEmailColumnsSection = () => {
    if (emailColumns.length > 0) {
      return (
        <div className='mt-4'>
          <div className="flex items-center mb-2 justify-between">
            <div className="text-sm text-slate-600">Email Column Name</div>
            <div className="text-sm text-slate-600">Email Data</div>
          </div>
          {renderEmailColumns()}
        </div>
      )
    }
  }

  const displayOtherColumns = (column) => {
    setOtherColumnDisplay(column)
    setAllColumnsVisible(!allColumnsVisible)
  }

  const renderSelectAnotherdomainColumnText = () => {
    if (domainColumns.length > 0) {
      let text = otherColumnDisplay === 'domain' ? "Hide other columns" : "Select another Domain column from CSV"
      return (
        <div 
          onClick={() => {
            if (otherColumnDisplay === 'domain') {
              displayOtherColumns(null);
            } else {
              displayOtherColumns('domain');
            }
          }} 
          className='mt-2 text-indigo-500 text-center cursor-pointer hover:opacity-50 inline-block w-min mx-auto whitespace-pre text-xs'
        >
          {text}
        </div>
      );
    }
  }

  const renderSelectAnotherEmailColumnText = () => {
    if (emailColumns.length > 0) {
      let text = otherColumnDisplay === 'email' ? "Hide other columns" : "Select another Email column from CSV"
      return (
        <div 
          onClick={() => {
            if (otherColumnDisplay === 'email') {
              displayOtherColumns(null);
            } else {
              displayOtherColumns('email');
            }
          }} 
          className='mt-2 text-indigo-500 text-center cursor-pointer hover:opacity-50 inline-block w-min mx-auto whitespace-pre text-xs'
        >
          {text}
        </div>
      );
    }
  }

  const invalidColumn = () => {
    console.log("LINKEDIN COL", leadColumn)
    return leadColumn !== null && leadColumn?.samples[0].includes('/in') === false
  }

  const renderWarning = () => {
    if (invalidColumn()) {
      return (
        <div className='p-4 mt-4 rounded-lg bg-red-50 text-sm flex flex-col items-center'>
          <div className="text-slate-600 mb-2">The column you selected does not seem to contain the correct data. Please confirm that you would like to continue with this column.</div>
          <Checkbox value={warningConfirmed} onClick={() => setWarningConfirmed(!warningConfirmed)} label="I confirm"/>
        </div>
      )
    }
  }

  const isValid = () => {
    // if (invalidColumn()) {
    //   return warningConfirmed && leadColumn !== null
    // }
    return leadColumn !== null || leadColumn !== null
  }

  return (
    <div className="p-10 rounded-lg border">
      <div className="flex items-center justify-between mb-4">
        <BackButton spacerBottom={false} onClick={() => onStageChange('input')}/>
        <div className="flex items-center gap-x-1">
          <Icons className="w-4 h-4" color="indigo-500" icon="file"/>
          <div className="text-sm text-indigo-500">{midTruncate(file.name, 12)}</div>
        </div>
      </div>
      {renderHeading()}
      {renderSubHeading()}
      {renderdomainColumnsSection()}
      {renderSelectAnotherdomainColumnText()}
      <div className={`p-4 border rounded-lg mt-4 ${otherColumnDisplay !== 'domain' ? 'hidden' : ''}`}>
        {renderNondomainColumns()}
      </div>
      <hr className='my-4 border-slate-200'/>
      {renderEmailColumnsSection()}
      {renderSelectAnotherEmailColumnText()}
      <div className={`p-4 border rounded-lg mt-4 ${otherColumnDisplay !== 'email' ? 'hidden' : ''}`}>
        {renderNonEmailColumns()}
      </div>
      {/* {renderWarning()} */}
      <Button displaySpinner={submitting} onClick={() => onSubmit()} disabled={!isValid()} spacerTop={true} text="Start Search"/>
    </div>
  )
}

export default SelectColumn;
