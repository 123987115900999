import {
  REWRITE_OFFER,
  REWRITE_OFFER_SUCCESS,
  REWRITE_OFFER_FAIL,
} from './types';
import { offerRewrite } from '../Backend';

export const rewriteOffer = (data, dispatch) => {
  return (dispatch) => {
    dispatch({ type: REWRITE_OFFER });
    offerRewrite(data).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          rewriteOfferSuccess(dispatch, data);
        });
      } else {
        response.json().then((error) => {
          rewriteOfferFail(dispatch, error);
        });
      }
    });
  };
};

const rewriteOfferSuccess = (dispatch, data) => {
  dispatch({
    type: REWRITE_OFFER_SUCCESS,
    payload: data
  });
};

const rewriteOfferFail = (dispatch, error) => {
  dispatch({
    type: REWRITE_OFFER_FAIL,
    payload: error
  });
};
