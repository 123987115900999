import React from 'react'
import { ReactComponent as Tick } from '../img/tick.svg'
import { ReactComponent as Cross } from '../img/cross.svg'
import { ReactComponent as File } from '../img/file.svg'
import { ReactComponent as Hamburger } from '../img/hamburger.svg'
import { ReactComponent as Logo } from '../img/logo.svg'
import { ReactComponent as Offer } from '../img/offer.svg'
import { ReactComponent as ToneOfVoice } from '../img/toneOfVoice.svg'
import { ReactComponent as Personalise } from '../img/personalise.svg'
import { ReactComponent as Hubspot } from '../img/hubspot.svg'
import Abstract from '../img/abstract.svg'
import classToHex from '../hooks/ClassToHex'

const Icons = ({ icon, color, onClick, className=null }) => {
  if (icon === 'logo') {
    return <Logo fill={classToHex(color)} alt={icon} className={className} onClick={onClick}/>  
  }
  if (icon === 'hamburger') {
    return <Hamburger fill={classToHex(color)} alt={icon} className={className} onClick={onClick}/>  
  }
  if (icon === 'tick') {
    return <Tick fill={classToHex(color)} alt={icon} className={className} onClick={onClick}/>  
  }
  if (icon === 'cross') {
    return <Cross fill={classToHex(color)} alt={icon} className={className} onClick={onClick}/>  
  }
  if (icon === 'file') {
    return <File fill={classToHex(color)} alt={icon} className={className} onClick={onClick}/>  
  }
  if (icon === 'toneOfVoice') {
    return <ToneOfVoice fill={classToHex(color)} alt={icon} className={className} onClick={onClick}/>  
  }
  if (icon === 'offer') {
    return <Offer fill={classToHex(color)} alt={icon} className={className} onClick={onClick}/>  
  }
  if (icon === 'personalise') {
    return <Personalise fill={classToHex(color)} alt={icon} className={className} onClick={onClick}/>  
  }
  if (icon === 'hubspot') {
    return <Hubspot fill={classToHex(color)} alt={icon} className={className} onClick={onClick}/>  
  }
  if (icon === 'abstract') {
    return <img src={Abstract} alt={icon} className={className} onClick={onClick}/>  
  }
}

export default Icons;