import React from 'react'
import Animations from './Animations'
// import Icons from './Icons'

const Button = ({ text, id, onClick, disabled, width="w-full", spacerTop, spacerBottom, forceBottom, displaySpinner, type="primary", className }) => {

  // const renderSpinner = () => {
  //   return <Animations animation="btnSpinner"/>
  // }

  const renderContent = () => {
    if (displaySpinner) {
      return <Animations className="w-6 h-6" animation="btnSpinner"/>
    }
    return text
  }

  const correctPadding = () => {
    if (displaySpinner) {
      return "py-0"
    }
    return "py-3"
  }

  const renderButton = () => {
    let primaryClass = "bg-indigo-500 text-white"
    let secondaryClass = "bg-slate-50 text-indigo-500"
    let primaryDarkClass = "bg-slate-200 text-gray-600"
    let special = "bg-purple-500 text-white"
    let signup = "bg-black rounded-lg text-white"
    let outline = "border-gray-800 border"
    let light = "bg-slate-100 text-gray-600"
    let specialGradient = "bgGradient text-white"

    let buttonClass;
    if (type === 'primary') {
      buttonClass = primaryClass
    } else if (type === 'secondary') {
      buttonClass = secondaryClass
    } else if (type === 'primaryDark') {
      buttonClass = primaryDarkClass
    } else if (type === 'special') {
      buttonClass = special
    } else if (type === 'specialGradient') {
      buttonClass = specialGradient
    } else if (type === 'outline') {
      buttonClass = outline
    } else if (type === 'light') {
      buttonClass = light
    } else if (type === 'signup') {
      buttonClass = signup
    }

    return <button 
      id={id} 
      className={`
        ${width ? width : "flex"} 
        ${buttonClass} 
        ${disabled ? "opacity-50" : "cursor-pointer hover:opacity-70"}
        ${correctPadding()}  
        ${spacerTop ? "mt-6" : ""}
        ${spacerBottom ? "mb-6" : ""}
        ${className}
        px-4 py-2 rounded-lg font-medium text-center 
      `}
      onClick={disabled ? "" : onClick}
      disabled={disabled}>
        {renderContent()}
      </button>
  }

  // if (forceBottom) {
  //   return (
  //     <div className="flex flex-col grow justify-end">
  //       {renderButton()}
  //     </div>
  //   )
  // }

  return (
    <>
      {renderButton()}
    </>
  )
}

export default Button;
